import React from "react";
import styles from "../../Pages/home/myCar/LiveMetrics.module.css";

function SpeedMetrics() {
  return (
    <div className={styles.liveMetrics__inner}>
      <div className={styles.liveMetrics__inner_summary}>
        <div>
          <h4>130 km</h4>
          <p>Mileage</p>
        </div>
        <div className={styles.demacation}></div>
        <div className={styles.end}>
          <h4>98 Km/h</h4>
          <p>Av,. Speed</p>
        </div>
      </div>
      <div className={styles.icon}>
        <svg
          width="18"
          height="18"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="8.15895"
            cy="8.83782"
            r="7.5"
            transform="rotate(-1.15004 8.15895 8.83782)"
            fill="#E2FBD7"
            stroke="#0AB34E"
          />
          <path
            d="M7.99475 15.0058L7.94481 15.0607L8.00525 15.0058C9.83904 13.339 11.2146 11.7968 12.1317 10.3792C13.0488 8.96176 13.5078 7.66854 13.5078 6.5C13.5078 5.03924 12.9275 3.6383 11.8946 2.60539C10.8617 1.57247 9.46076 0.992188 8 0.992188C6.53924 0.992188 5.1383 1.57247 4.10539 2.60539C3.07247 3.6383 2.49219 5.03924 2.49219 6.5C2.49219 7.66854 2.95122 8.96176 3.86832 10.3792C4.78543 11.7968 6.16096 13.339 7.99475 15.0058ZM11.1765 3.32354C12.0189 4.16599 12.4922 5.3086 12.4922 6.5C12.4922 7.42098 12.1237 8.48252 11.377 9.67435C10.6312 10.8647 9.5085 12.1845 8 13.6232C6.4915 12.1845 5.36877 10.8647 4.623 9.67435C3.87629 8.48252 3.50781 7.42098 3.50781 6.5C3.50781 5.3086 3.9811 4.16599 4.82354 3.32354C5.66599 2.4811 6.8086 2.00781 8 2.00781C9.1914 2.00781 10.334 2.4811 11.1765 3.32354Z"
            fill="#FD4D1E"
            stroke="#0AB34E"
            stroke-width="0.015625"
          />
          <path
            d="M8 8C8.39782 8 8.77936 7.84196 9.06066 7.56066C9.34196 7.27936 9.5 6.89782 9.5 6.5C9.5 6.10218 9.34196 5.72064 9.06066 5.43934C8.77936 5.15804 8.39782 5 8 5C7.60218 5 7.22064 5.15804 6.93934 5.43934C6.65804 5.72064 6.5 6.10218 6.5 6.5C6.5 6.89782 6.65804 7.27936 6.93934 7.56066C7.22064 7.84196 7.60218 8 8 8ZM8 9C7.33696 9 6.70107 8.73661 6.23223 8.26777C5.76339 7.79893 5.5 7.16304 5.5 6.5C5.5 5.83696 5.76339 5.20107 6.23223 4.73223C6.70107 4.26339 7.33696 4 8 4C8.66304 4 9.29893 4.26339 9.76777 4.73223C10.2366 5.20107 10.5 5.83696 10.5 6.5C10.5 7.16304 10.2366 7.79893 9.76777 8.26777C9.29893 8.73661 8.66304 9 8 9Z"
            fill="#FD4D1E"
          />
        </svg>
      </div>
      <div className={styles.liveMetrics__inner_summary}>
        <div>
          <h4>27.80 Litre</h4>
          <p>Av. Fuel Consume</p>
        </div>
        <div className={styles.demacation}></div>
        <div className={styles.end}>
          <h4>₦5,000</h4>
          <p>Cost</p>
        </div>
      </div>
    </div>
  );
}

export default SpeedMetrics;
