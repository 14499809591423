import axios from "axios";

const url = process.env.REACT_APP_API_URL_BPMS;

const CarPapersAPi = {
  getPaperData: (payload) => {
    const res = axios({
      url: `${url}/premiumpapers/app/papers/${payload.plate_no}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getCarPapersState: (payload) => {
    const response = axios({
      url: `https://bpms.motormata.com/api/v1/noauth/cusinfo/${payload}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  getPapersForVehicle: (payload) => {
    const response = axios({
      url: `${url}/main/app/papers/${payload.plate_number}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  addToCartHandler: (payload) => {
    const response = axios({
      url: `${url}/main/app/set/papers/addToCart?plate_no=${payload.plate_no}&cart_type=${payload.cart_type}&product_id=${payload.id}`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  removeFromCartHandler: (payload) => {
    const response = axios({
      url: `${url}/main/app/set/papers/removeFromCart?plate_no=${payload.plate_no}&cart_type=${payload.cart_type}&product_id=${payload.id}`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },
  cartCheckoutHandler: (payload) => {
    const response = axios({
      url: `${url}/main/app/papers/checkout/${payload.plate_number}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  couponVerification: (payload) => {
    const response = axios({
      url: `${url}/main/app/set/papers/coupon/verification/${payload.OTP}/${payload.id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  submitOrderHandler: (payload) => {
    const response = axios({
      url: `${url}/main/app/set/papers/uploadOrder`,
      method: "POST",
      data: JSON.stringify({
        customer_id: payload.customer_id,
        firstname: payload.firstname,
        lastname: payload.lastname,
        street: payload.street,
        location: payload.location,
        service_delivery_date: payload.service_delivery_date,
        order_type: payload.order_type,
        coupon_code: payload.coupon_code,
        city: payload.city,
        state: payload.state,
        plateno: payload.plateno,
        phone: payload.phone,
        discount: payload.discount,
        products: payload.products,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  generateBankAccount: (payload) => {
    const response = axios({
      url: `${url}/main/app/papers/generateAccount/${payload.order}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },
};
export default CarPapersAPi;
