import React from "react";
import BottomNav from "../../../Components/BottomNav";
import NavBar from "../../../Components/interface/NavBar";
import Home from "../smartscan/Home";
import styles from "../services/Services.module.css";

function SmartScan(props) {
  return (
    <section>
      <NavBar title="Smart Scan" setShowMenu={props.setShowMenu} />
      <div className={styles.head}>
        <div className={styles.main_hero}>
          <Home />
        </div>
      </div>
      <BottomNav />
    </section>
  );
}

export default SmartScan;
