import React from "react";
import { Link } from "react-router-dom";
import Button from "../../Components/interface/Button";
import WhiteButton from "../../Components/interface/WhiteButton";
import styles from "./InsufficientBallance.module.css";

function InsufficientBallance() {
  return (
    <div className={styles.body}>
      <div className={styles.body__cancelButton}>
        <Link to="/">
          <div className={styles.body__cancelButton__inner}>
            <div className={styles.bar1}></div>
            <div className={styles.bar2}></div>
          </div>
        </Link>
      </div>
      <h4>Wallet Balance</h4>
      <div className={styles.heading}>
        <div>
          <h3>₦ 0.00 </h3>
          <p>Wallet balance</p>
        </div>
        <div>
          <p>15-10-2022</p>
          <p>10:44:35 AM</p>
        </div>
      </div>
      <div className={styles.desc}>
        <h3>Insufficient fund</h3>
        <h2>Attention!</h2>
        <p>The diagnosis has been suspended, </p>
      </div>
      <div className={styles.warning}>
        <p>
          To diagnose your vehicle, kindly top up your wallet. Click the button
          below to fund your wallet
        </p>
      </div>
      <div className={styles.buttons}>
        <Link to="/">
          <Button>Top up your wallet</Button>
        </Link>
        <Link to="/">
          <WhiteButton>Back</WhiteButton>
        </Link>
      </div>
    </div>
  );
}

export default InsufficientBallance;
