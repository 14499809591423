import React, { useEffect, useState } from "react";
import styles from "./Services.module.css";
import workshop from "../../../asset/images/workshop.png";
import FilteredInput from "../../../Components/inputs/FilteredInput";
import axios from "axios";
import SubscriptionModal from "../../../Components/UI/SubscriptionModal";
import BookingModal from "../../../Components/UI/BookingModal";
import Ticket from "../../../Components/UI/Ticket";
import Modal from "../../../Components/UI/Modal";

const list = [
  "Routine Services",
  "Oil Change",
  "Physical Inspection",
  "Diagnosis",
];

function WorkShopList(props) {
  const [modal, setModal] = useState(false);
  const [item, setItem] = useState("");
  const [listNames, setListNames] = useState([]);
  const [openTicketModal, setOpenTicketModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [filteredResults, setFilteredResults] = useState(listNames);

  useEffect(() => {
    axios
      .get(`https://api.airtable.com/v0/appjrC0uNmYE0aZho/workshops`, {
        headers: {
          Authorization: `Bearer pat6OPIoJE33MducG.73a05143632252510c39d3ead36996b5353c5d775d68645e2ff4932eabd1e52d`,
        },
      })
      .then((response) => {
        setListNames(response.data.records);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    // const filterResults = listNames?.filter((data, index) =>
    //   data.fields.Category?.includes(item)
    // );
    const searchFilter = listNames?.filter((data, index) =>
      data.fields.City?.toLowerCase()?.includes(props.searchInput.toLowerCase())
    );
    setFilteredResults(searchFilter);
  }, [item, listNames, props.searchInput]);

  const arrangedItems = [...filteredResults]?.sort((a, b) =>
    a.fields["Workshop Name"].localeCompare(b.fields["Workshop Name"])
  );

  const modalCloseHandler = () => {
    setModal(false);
    setOpenTicketModal(false);
  };

  const openBookingModal = () => {
    setModal(true);
    setItem("info");
  };

  const openTicketModalHandler = () => {
    setOpenTicketModal(true);
  };

  const backHandler = () => {
    setSuccess(false);
  };

  return (
    <div className={styles.workShop}>
      {modal && item === "Routine Services" && (
        <SubscriptionModal
          onClick={openBookingModal}
          close={modalCloseHandler}
        />
      )}

      {modal && item !== "Routine Services" && (
        <BookingModal
          openTicketModalHandler={openTicketModalHandler}
          close={modalCloseHandler}
        />
      )}

      {openTicketModal && (
        <Ticket setSuccess={setSuccess} close={modalCloseHandler} />
      )}
      {success && (
        <Modal
          title=" Booked Successfully"
          message="Thank you for using Motormata. Please expect a call from us to confirm
your booking"
          close={backHandler}
          back="Ok"
          accept=""
          confirm={backHandler}
          success="true"
        />
      )}
      <div className={styles.filter}>
        <FilteredInput
          title="Filter by"
          listNames={list}
          setModal={setModal}
          filter={setItem}
        />
      </div>
      <div className={styles.workshop_wrapper}>
        {/* <div className={styles.workShop_head}>
          <p>{`${item}` || `Vetted Workshops`}</p>
        </div> */}
        <div className={styles.workshop_list_wrapper}>
          {arrangedItems?.map((data, index) => (
            <div key={index} className={styles.workShopResultList}>
              <div className={styles.workshopImgWrapper}>
                <img
                  src={data.fields["Image URL"] || workshop}
                  alt="workshop"
                />
              </div>
              <div className={styles.desc}>
                <h4>{data.fields["Workshop Name"]}</h4>
                <p>{data.fields.Address}</p>
                <div className={styles.ratings}>
                  {/* <p>City : {data.fields.City}</p> */}
                </div>
              </div>
              <div className={styles.icon} onClick={openBookingModal}>
                <p>Book</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default WorkShopList;
