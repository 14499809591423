import React from "react";
import Transactions from "../../Components/customer-profile/Transactions";
import styles from "./Account.module.css";

function RecentTransaction(props) {
  return (
    <div className={styles.transactionList_wrapper}>
      {props?.walletData?.results?.map((data, index) => {
        const creditData = data?.transaction_type === "DEBIT" ? false : true;
        return <Transactions credit={creditData} product={data} />;
      })}
    </div>
  );
}

export default RecentTransaction;
