import React, { useCallback, useEffect, useState } from "react";
import BottomNav from "../../../Components/BottomNav";
import NavBar from "../../../Components/interface/NavBar";
import styles from "./Papers.module.css";
import Input from "../../../Components/interface/Input";
import Button from "../../../Components/interface/Button";
import { useDispatch, useSelector } from "react-redux";
import states from "../../../utils/States";
import { useNavigate } from "react-router";
import { authActions } from "../../../store/redux-store/Index";
import CarPapersAPi from "../../../utils/api/CarPapersApi";

function Checkout(props) {
  const [selectInput2, setSelectInput2] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [locationId, setLocationId] = useState("");
  const [data, setData] = useState([]);
  const [inValidAddresss, setInvalidAddress] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);

  const personalData = useSelector((data) => data.auth.generalLoginData);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const delivery = data.cart_list?.find((data) =>
    data?.product_title.includes("Delivery")
  );

  const deliveryProducts = data.cart_list?.filter(
    (data) => data?.product_title !== "Delivery Fee Only"
  );

  const checkoutCallback = useCallback(() => {
    const getPapersHandler = async () => {
      try {
        const checkoutRes = await CarPapersAPi.cartCheckoutHandler({
          plate_number: personalData[0]?.vehicle_data[0]?.plate_number,
        });
        setData(checkoutRes.data);
      } catch (err) {
        console.log(err?.response?.data?.error);
      }
    };
    getPapersHandler();
  }, [personalData]);

  useEffect(() => {
    checkoutCallback();
  }, [checkoutCallback]);

  useEffect(() => {
    if (
      selectInput2.trim().length >= 1 &&
      state.trim().length >= 1 &&
      address.trim().length >= 1
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [selectInput2, address, state]);

  useEffect(() => {
    let Id = data?.locations?.find(
      (data) => selectInput2 === data?.location_name
    );
    setLocationId(Id);
  }, [data?.locations, locationId, selectInput2]);

  let brandSelectChangeHandler = (e) => {
    setSelectInput2(e.target.value);
  };

  const stateSelectChangeHandler = (e) => {
    setState(e.target.value);
  };
  const handleInputChange = (e) => {
    setAddress(e.target.value);
  };
  const addressInputBlur = () => {
    if (address.trim().length === 0) {
      setInvalidAddress(true);
    } else {
      setInvalidAddress(false);
    }
  };

  const ButtonNavigateHandler = () => {
    dispatch(authActions.clearDeliveryAdd());
    const deliveryDetails = {
      state: state,
      zone: selectInput2,
      street: address,
      location: locationId.id,
    };
    dispatch(authActions.deliveryAdd(deliveryDetails));
    deliveryProducts?.map((data) => {
      return dispatch(authActions.magentoAdd(data?.magento_product_id));
    });
    navigate("/papers/invoice");
  };

  function truncate(input) {
    if (input.length > 8) {
      return input.substring(0, 43) + "...";
    }
    return input;
  }

  const commafy = (num) => {
    return num?.toLocaleString();
  };

  return (
    <div>
      <NavBar title="Cart" setShowMenu={props.setShowMenu} />
      <div className={styles.head}>
        <div className={styles.main_hero}>
          <div className={styles.cartNav}>
            <p>CART</p>
            <p style={{ color: "#008069", borderBottom: "5px solid #008069" }}>
              DELIVERY
            </p>
            <p>PAYMENT</p>
          </div>
          <div className={styles.cartNav_progress}></div>
        </div>
        <div>
          <p>Delivery to</p>
          <div className={styles.contact_cus}>
            <p>{personalData[0].fullname}</p>
          </div>
          <div className={styles.contact_cus}>
            <p>{personalData[0].phone}</p>
          </div>
          <div className={styles.form__inner}>
            <div className={styles.form__passwordInput}>
              <select value={selectInput2} onChange={brandSelectChangeHandler}>
                {data?.locations?.map((data, index) => (
                  <option value={data.location_name}>
                    {truncate(data.location_name)}
                  </option>
                ))}
              </select>
            </div>
            <div className={styles.form__passwordInput}>
              <select value={state} onChange={stateSelectChangeHandler}>
                {states?.map((data, index) => (
                  <option value={data}>{data}</option>
                ))}
              </select>
            </div>
            <div className={styles.form__inner}>
              <Input
                onBlur={addressInputBlur}
                invalid={inValidAddresss}
                onChange={handleInputChange}
                value={address}
                type="text"
                placeholder="Enter your address"
                name="number"
              />
            </div>
            <div className={styles.total2}>
              <div>
                <p>Sub Total</p>
                <h4>₦ {commafy(data?.cart_sum - delivery?.price)}</h4>
              </div>
              <div>
                <p>Delivery Fee</p>
                <h4>₦ {commafy(delivery?.price)}</h4>
              </div>
              <div className={styles.grandTotal}>
                <p>Grand Total</p>
                <h4>₦ {commafy(data?.cart_sum || 0)}</h4>
              </div>
            </div>
            <div className={styles.button2}>
              <Button onClick={ButtonNavigateHandler} disabled={!formIsValid}>
                Proceed to Payment
              </Button>
            </div>
          </div>
        </div>
      </div>
      <BottomNav />
    </div>
  );
}

export default Checkout;
