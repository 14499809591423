import axios from "axios";

const url = process.env.REACT_APP_API_URL;
const googleUrl = `https://maps.googleapis.com/maps/api`;
const key = process.env.REACT_APP_API_KEY;

const Mail_UserID = `Z5ZG0Ua9CXbWizkTO`;

const Service_ID = `service_04mgbhw`;

const template_id = `template_fb93vwr`;

const CustomerScans = {
  getAllScans: (payload) => {
    const res = axios({
      url: `${url}/customer/mainapp/scans/`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  sendTicket: (payload) => {
    const response = axios({
      url: `https://api.emailjs.com/api/v1.0/email/send`,
      method: "POST",
      data: JSON.stringify({
        service_id: Service_ID,
        template_id: template_id,
        user_id: Mail_UserID,
        template_params: {
          to_name: payload.to_name,
          from_name: payload.from_name,
          message: payload.message,
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  getVendorReports: (payload) => {
    const res = axios({
      url: `${url}/customer/vendor/reports/`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getVendorReportsDetails: (payload) => {
    const response = axios({
      url: `${url}/customer/vendor/${payload.report_id}/report/details/`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  vinDecoder: (payload) => {
    const res = axios({
      url: `${url}/customer/mainapp/vindecoder/${payload.vin}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  scanVehiclesHandler: (payload) => {
    const response = axios({
      url: `${url}/customer/mainapp/scan/`,
      method: "POST",
      data: JSON.stringify({
        user: payload.user,
        device: payload.device,
      }),
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  getCusSubscription: (payload) => {
    const res = axios({
      url: `${url}/customer/mainapp/${payload.sub_id}/subscription/`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getCusScanDetails: (payload) => {
    const res = axios({
      url: `${url}/customer/mainapp/${payload.scan_id}/scan/details/`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getDevices: (payload) => {
    const res = axios({
      url: `${url}/customer/mainapp/${payload.id}/devices/`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getUserAddress: (payload) => {
    const res = axios({
      url: `${googleUrl}/geocode/json?latlng=${payload.lat},${payload.lng}&key=${key}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  },
};

export default CustomerScans;
