import React from "react";
import styles from "./Services.module.css";
import item1 from "../../../asset/images/items/spring.png";
import item2 from "../../../asset/images/items/tyre.png";
import item3 from "../../../asset/images/items/battery.png";
import FilteredInput from "../../../Components/inputs/FilteredInput";
import { Link } from "react-router-dom";
import SpareParts from "../../../utils/SparePartsArray";
import { useDispatch, useSelector } from "react-redux";
import { partsActions } from "../../../store/redux-store/spare-parts";

// import { Link } from "react-router-dom";

const list = [
  "Accessories",
  "Body Parts",
  "Underneath",
  "Interior Parts",
  "Engine Parts",
];

function SparepartsList({ showFilter }) {
  const quantity = useSelector((data) => data.parts.totalQuantity);

  // const navigate = useNavigate();
  // const [item, setItem] = useState("");
  // filter={setItem}
  // const backHandler = () => {
  //   navigate("/");
  // };
  const dispatch = useDispatch();

  const addToCartHandler = (item) => {
    dispatch(
      partsActions.addProduct({
        id: item.id,
        title: item.title,
        model: item.model,
        name: item.name,
        quantity: 1,
      })
    );
  };

  return (
    <div className={styles.workShop}>
      {/* <Modal
        title="Maintenance"
        message="This feature is coming soon..."
        accept="Back"
        back=""
        close={backHandler}
        confirm={backHandler}
      /> */}
      {showFilter && (
        <div className={styles.filter}>
          <FilteredInput title="Car Parts Sections" listNames={list} />
        </div>
      )}
      <div className={styles.spareParts_wrapper}>
        <div className={styles.spareParts}>
          {" "}
          <p className={styles.para}>Post</p>{" "}
          <div className={styles.spareParts_inner}>
            {SpareParts.map((data, index) => (
              <div className={styles.spareParts_card} key={index}>
                {/* <p>{item}</p> */}
                <div className={styles.spareParts_card_image_wrapper}>
                  <div className={styles.spareParts_card_image_wrapper_inner}>
                    <img src={item1} alt="parts" />
                  </div>
                </div>
                <div className={styles.spareParts_name}>
                  <div>
                    {" "}
                    <p>{data.title}</p>
                  </div>

                  <div>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.99992 13.999L7.03325 13.1323C5.91103 12.1212 4.98325 11.249 4.24992 10.5156C3.51659 9.78229 2.93325 9.12385 2.49992 8.54029C2.06659 7.95718 1.76392 7.42118 1.59192 6.93229C1.41992 6.4434 1.3337 5.9434 1.33325 5.43229C1.33325 4.38785 1.68325 3.51562 2.38325 2.81562C3.08325 2.11562 3.95547 1.76562 4.99992 1.76562C5.5777 1.76562 6.1277 1.88785 6.64992 2.13229C7.17214 2.37674 7.62214 2.72118 7.99992 3.16562C8.3777 2.72118 8.8277 2.37674 9.34992 2.13229C9.87214 1.88785 10.4221 1.76562 10.9999 1.76562C12.0444 1.76562 12.9166 2.11562 13.6166 2.81562C14.3166 3.51562 14.6666 4.38785 14.6666 5.43229C14.6666 5.9434 14.5804 6.4434 14.4079 6.93229C14.2355 7.42118 13.9328 7.95718 13.4999 8.54029C13.0666 9.12385 12.4833 9.78229 11.7499 10.5156C11.0166 11.249 10.0888 12.1212 8.96659 13.1323L7.99992 13.999ZM7.99992 12.199C9.06659 11.2434 9.94436 10.4238 10.6333 9.74029C11.3221 9.05674 11.8666 8.46251 12.2666 7.95763C12.6666 7.45185 12.9444 7.00163 13.0999 6.60696C13.2555 6.21229 13.3333 5.82074 13.3333 5.43229C13.3333 4.76562 13.111 4.21007 12.6666 3.76562C12.2221 3.32118 11.6666 3.09896 10.9999 3.09896C10.4777 3.09896 9.99436 3.24607 9.54992 3.54029C9.10547 3.83451 8.79992 4.20963 8.63325 4.66563H7.36659C7.19992 4.21007 6.89436 3.83496 6.44992 3.54029C6.00547 3.24562 5.52214 3.09851 4.99992 3.09896C4.33325 3.09896 3.7777 3.32118 3.33325 3.76562C2.88881 4.21007 2.66659 4.76562 2.66659 5.43229C2.66659 5.82118 2.74436 6.21296 2.89992 6.60763C3.05547 7.00229 3.33325 7.45229 3.73325 7.95763C4.13325 8.46296 4.6777 9.0574 5.36659 9.74096C6.05547 10.4245 6.93325 11.2438 7.99992 12.199Z"
                        fill="#008069"
                      />
                    </svg>
                  </div>
                </div>
                <div className={styles.spareParts_name}>
                  <div> </div>

                  <div onClick={() => addToCartHandler(data)}>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.5 13.5C5.77614 13.5 6 13.2761 6 13C6 12.7239 5.77614 12.5 5.5 12.5C5.22386 12.5 5 12.7239 5 13C5 13.2761 5.22386 13.5 5.5 13.5Z"
                        stroke="#008069"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.5 13.5C12.7761 13.5 13 13.2761 13 13C13 12.7239 12.7761 12.5 12.5 12.5C12.2239 12.5 12 12.7239 12 13C12 13.2761 12.2239 13.5 12.5 13.5Z"
                        stroke="#008069"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1.5 2.5H3.5L5 11H13"
                        stroke="#008069"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5 9H12.795C12.8528 9.00004 12.9089 8.98004 12.9536 8.9434C12.9983 8.90676 13.029 8.85576 13.0403 8.79906L13.9403 4.29906C13.9476 4.26278 13.9467 4.22533 13.9377 4.18943C13.9288 4.15352 13.9119 4.12006 13.8885 4.09145C13.865 4.06284 13.8355 4.03979 13.802 4.02398C13.7686 4.00816 13.732 3.99997 13.695 4H4"
                        stroke="#008069"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            ))}{" "}
          </div>
          <p className={styles.para2}>See more</p>{" "}
        </div>

        <div className={styles.spareParts}>
          {" "}
          <p className={styles.para}>Recommended</p>{" "}
          <div className={styles.spareParts_inner}>
            {SpareParts.map((data, index) => (
              <div className={styles.spareParts_card} key={index}>
                {/* <p>{item}</p> */}
                <div className={styles.spareParts_card_image_wrapper}>
                  <div className={styles.spareParts_card_image_wrapper_inner}>
                    <img src={item2} alt="parts" />
                  </div>
                </div>
                <div className={styles.spareParts_name}>
                  <div>
                    {" "}
                    <p>{data.title}</p>
                  </div>

                  <div>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.99992 13.999L7.03325 13.1323C5.91103 12.1212 4.98325 11.249 4.24992 10.5156C3.51659 9.78229 2.93325 9.12385 2.49992 8.54029C2.06659 7.95718 1.76392 7.42118 1.59192 6.93229C1.41992 6.4434 1.3337 5.9434 1.33325 5.43229C1.33325 4.38785 1.68325 3.51562 2.38325 2.81562C3.08325 2.11562 3.95547 1.76562 4.99992 1.76562C5.5777 1.76562 6.1277 1.88785 6.64992 2.13229C7.17214 2.37674 7.62214 2.72118 7.99992 3.16562C8.3777 2.72118 8.8277 2.37674 9.34992 2.13229C9.87214 1.88785 10.4221 1.76562 10.9999 1.76562C12.0444 1.76562 12.9166 2.11562 13.6166 2.81562C14.3166 3.51562 14.6666 4.38785 14.6666 5.43229C14.6666 5.9434 14.5804 6.4434 14.4079 6.93229C14.2355 7.42118 13.9328 7.95718 13.4999 8.54029C13.0666 9.12385 12.4833 9.78229 11.7499 10.5156C11.0166 11.249 10.0888 12.1212 8.96659 13.1323L7.99992 13.999ZM7.99992 12.199C9.06659 11.2434 9.94436 10.4238 10.6333 9.74029C11.3221 9.05674 11.8666 8.46251 12.2666 7.95763C12.6666 7.45185 12.9444 7.00163 13.0999 6.60696C13.2555 6.21229 13.3333 5.82074 13.3333 5.43229C13.3333 4.76562 13.111 4.21007 12.6666 3.76562C12.2221 3.32118 11.6666 3.09896 10.9999 3.09896C10.4777 3.09896 9.99436 3.24607 9.54992 3.54029C9.10547 3.83451 8.79992 4.20963 8.63325 4.66563H7.36659C7.19992 4.21007 6.89436 3.83496 6.44992 3.54029C6.00547 3.24562 5.52214 3.09851 4.99992 3.09896C4.33325 3.09896 3.7777 3.32118 3.33325 3.76562C2.88881 4.21007 2.66659 4.76562 2.66659 5.43229C2.66659 5.82118 2.74436 6.21296 2.89992 6.60763C3.05547 7.00229 3.33325 7.45229 3.73325 7.95763C4.13325 8.46296 4.6777 9.0574 5.36659 9.74096C6.05547 10.4245 6.93325 11.2438 7.99992 12.199Z"
                        fill="#008069"
                      />
                    </svg>
                  </div>
                </div>
                <div className={styles.spareParts_name}>
                  <div> </div>

                  <div onClick={() => addToCartHandler(data)}>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.5 13.5C5.77614 13.5 6 13.2761 6 13C6 12.7239 5.77614 12.5 5.5 12.5C5.22386 12.5 5 12.7239 5 13C5 13.2761 5.22386 13.5 5.5 13.5Z"
                        stroke="#008069"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.5 13.5C12.7761 13.5 13 13.2761 13 13C13 12.7239 12.7761 12.5 12.5 12.5C12.2239 12.5 12 12.7239 12 13C12 13.2761 12.2239 13.5 12.5 13.5Z"
                        stroke="#008069"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1.5 2.5H3.5L5 11H13"
                        stroke="#008069"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5 9H12.795C12.8528 9.00004 12.9089 8.98004 12.9536 8.9434C12.9983 8.90676 13.029 8.85576 13.0403 8.79906L13.9403 4.29906C13.9476 4.26278 13.9467 4.22533 13.9377 4.18943C13.9288 4.15352 13.9119 4.12006 13.8885 4.09145C13.865 4.06284 13.8355 4.03979 13.802 4.02398C13.7686 4.00816 13.732 3.99997 13.695 4H4"
                        stroke="#008069"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                {/* <div className={styles.cartBtn}>
                  <p>Request for price</p>
                </div> */}
              </div>
            ))}{" "}
          </div>
          <p className={styles.para2}>See more</p>{" "}
        </div>

        <div className={styles.spareParts}>
          {" "}
          <p className={styles.para}>Others</p>{" "}
          <div className={styles.spareParts_inner}>
            {SpareParts.map((data, index) => (
              <div className={styles.spareParts_card} key={index}>
                {/* <p>{item}</p> */}
                <div className={styles.spareParts_card_image_wrapper}>
                  <div className={styles.spareParts_card_image_wrapper_inner}>
                    <img src={item3} alt="parts" />
                  </div>
                </div>
                <div className={styles.spareParts_name}>
                  <div>
                    {" "}
                    <p>{data.title}</p>
                  </div>

                  <div>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.99992 13.999L7.03325 13.1323C5.91103 12.1212 4.98325 11.249 4.24992 10.5156C3.51659 9.78229 2.93325 9.12385 2.49992 8.54029C2.06659 7.95718 1.76392 7.42118 1.59192 6.93229C1.41992 6.4434 1.3337 5.9434 1.33325 5.43229C1.33325 4.38785 1.68325 3.51562 2.38325 2.81562C3.08325 2.11562 3.95547 1.76562 4.99992 1.76562C5.5777 1.76562 6.1277 1.88785 6.64992 2.13229C7.17214 2.37674 7.62214 2.72118 7.99992 3.16562C8.3777 2.72118 8.8277 2.37674 9.34992 2.13229C9.87214 1.88785 10.4221 1.76562 10.9999 1.76562C12.0444 1.76562 12.9166 2.11562 13.6166 2.81562C14.3166 3.51562 14.6666 4.38785 14.6666 5.43229C14.6666 5.9434 14.5804 6.4434 14.4079 6.93229C14.2355 7.42118 13.9328 7.95718 13.4999 8.54029C13.0666 9.12385 12.4833 9.78229 11.7499 10.5156C11.0166 11.249 10.0888 12.1212 8.96659 13.1323L7.99992 13.999ZM7.99992 12.199C9.06659 11.2434 9.94436 10.4238 10.6333 9.74029C11.3221 9.05674 11.8666 8.46251 12.2666 7.95763C12.6666 7.45185 12.9444 7.00163 13.0999 6.60696C13.2555 6.21229 13.3333 5.82074 13.3333 5.43229C13.3333 4.76562 13.111 4.21007 12.6666 3.76562C12.2221 3.32118 11.6666 3.09896 10.9999 3.09896C10.4777 3.09896 9.99436 3.24607 9.54992 3.54029C9.10547 3.83451 8.79992 4.20963 8.63325 4.66563H7.36659C7.19992 4.21007 6.89436 3.83496 6.44992 3.54029C6.00547 3.24562 5.52214 3.09851 4.99992 3.09896C4.33325 3.09896 3.7777 3.32118 3.33325 3.76562C2.88881 4.21007 2.66659 4.76562 2.66659 5.43229C2.66659 5.82118 2.74436 6.21296 2.89992 6.60763C3.05547 7.00229 3.33325 7.45229 3.73325 7.95763C4.13325 8.46296 4.6777 9.0574 5.36659 9.74096C6.05547 10.4245 6.93325 11.2438 7.99992 12.199Z"
                        fill="#008069"
                      />
                    </svg>
                  </div>
                </div>
                <div className={styles.spareParts_name}>
                  <div> </div>

                  <div onClick={() => addToCartHandler(data)}>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.5 13.5C5.77614 13.5 6 13.2761 6 13C6 12.7239 5.77614 12.5 5.5 12.5C5.22386 12.5 5 12.7239 5 13C5 13.2761 5.22386 13.5 5.5 13.5Z"
                        stroke="#008069"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.5 13.5C12.7761 13.5 13 13.2761 13 13C13 12.7239 12.7761 12.5 12.5 12.5C12.2239 12.5 12 12.7239 12 13C12 13.2761 12.2239 13.5 12.5 13.5Z"
                        stroke="#008069"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1.5 2.5H3.5L5 11H13"
                        stroke="#008069"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5 9H12.795C12.8528 9.00004 12.9089 8.98004 12.9536 8.9434C12.9983 8.90676 13.029 8.85576 13.0403 8.79906L13.9403 4.29906C13.9476 4.26278 13.9467 4.22533 13.9377 4.18943C13.9288 4.15352 13.9119 4.12006 13.8885 4.09145C13.865 4.06284 13.8355 4.03979 13.802 4.02398C13.7686 4.00816 13.732 3.99997 13.695 4H4"
                        stroke="#008069"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            ))}{" "}
          </div>
          <p className={styles.para2}>See more</p>{" "}
        </div>
      </div>

      {quantity !== 0 && (
        <Link to="/cart">
          <div className={styles.cart_button}>
            <p>{quantity} Items Added</p>
            <p> Go to Cart</p>
          </div>
        </Link>
      )}
    </div>
  );
}

export default SparepartsList;
