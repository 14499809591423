import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./Login.module.css";
import Logo from "../../Components/interface/Logo";
import PasswordInput from "../../Components/interface/PasswordInput";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../../Components/interface/Button";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/redux-store/Index";
import EmailInput from "../../Components/interface/EmailInput";
import jwt_decode from "jwt-decode";
import Authentication from "../../utils/api/Authentication";

const initialValues = {
  password: "",
  email: "",
  number: "",
};
const initialBoolenValues = {
  toggle: false,
  passwordInvalid: false,
  phoneNumberInvalid: false,
  formIsValid: false,
  validEmail: false,
};

function Login() {
  const [values, setValues] = useState(initialValues);
  const [boolen, setBoolen] = useState(initialBoolenValues);
  const [loading, setLoading] = useState(false);

  //Form Validation//
  useEffect(() => {
    if (values.password.trim().length >= 1) {
      setBoolen({ formIsValid: true });
    } else {
      setBoolen({ formIsValid: false });
    }
  }, [values.password]);

  useEffect(() => {
    if (
      values.email.trim().length >= 1 &&
      values.email.includes("@") &&
      values.email.includes(".com")
    ) {
      setBoolen({ validEmail: true });
    } else {
      setBoolen({ validEmail: false });
    }
  }, [values.email]);

  //Form Validation end//
  let dispatch = useDispatch();
  let navigate = useNavigate();
  //Input change handler//
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  //Input change handler end//

  //Form submit Handler//
  let submitHandler = async (e) => {
    e.preventDefault();
    const regex = /^\+?\d{11}(?:\d{2})?$/;

    const emailInput = regex.test(values.email)
      ? `${values.email}@email.com`
      : values.email;

    try {
      setLoading(true);

      // Endpoint to log a user in and get the access token
      const res = await Authentication.loginHandler({
        email: emailInput,
        phone: "NO DATA",
        password: values.password,
      });
      dispatch(authActions.resetLoginDetails());
      dispatch(authActions.login(res.data));

      // decode jwt token
      const decoded = jwt_decode(res.data.access);
      dispatch(authActions.loginData(decoded));

      // Endpoint to get the customers information
      const userDetails = await Authentication.fetchUserDetails({
        user_id: decoded.user_id,
        access_token: res.data.access,
      });

      dispatch(authActions.generalLoginData(userDetails.data));
      navigate("/home");
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.errors, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  //Form submit Handler end//

  //Password Toggle function//
  let passwordToggleHandler = () => {
    setBoolen({ ...boolen, toggle: !boolen.toggle });
  };

  let passwordInputBlur = () => {
    if (values.password.trim().length === 0) {
      setBoolen({ ...boolen, passwordInvalid: true });
    } else {
      setBoolen({ ...boolen, passwordInvalid: false });
    }
  };
  //onBlur function handler end//

  let type = boolen.toggle ? "name" : "password";
  return (
    <section className={styles.login}>
      <ToastContainer />
      <div className={styles.login__hero}>
        <Logo />
        <div className={styles.header}>
          <p>Login</p>
        </div>
      </div>
      <div className={styles.form}>
        <form onSubmit={submitHandler}>
          <EmailInput
            type="text"
            value={values.email}
            label="Email or Phone Number"
            onChange={handleInputChange}
            placeholder="Enter email or Phone number"
            validEmail={boolen.validEmail}
            name="email"
          />

          <PasswordInput
            label="Password"
            passwordInvalid={boolen.passwordInvalid}
            value={values.password}
            onChange={handleInputChange}
            onBlur={passwordInputBlur}
            type={type}
            name="password"
            placeholder="Enter password"
            onClick={passwordToggleHandler}
            toggle={boolen.toggle}
          />
          <div className={styles.forgotPassword}>
            <span>
              <Link to="/forgotpassword">Forgot Password?</Link>
            </span>
          </div>
          {!loading && <Button disabled={!boolen.formIsValid}>Login</Button>}
          {loading && <Button disabled={boolen.formIsValid}>Loading...</Button>}
        </form>
        <div className={styles.login__options}>
          <p>
            Don’t have an account?{" "}
            <span>
              {" "}
              <Link to="/signup">Sign up</Link>
            </span>
          </p>
        </div>
      </div>
    </section>
  );
}

export default Login;
