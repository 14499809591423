import React, { useCallback, useEffect, useState } from "react";
import NavBar from "../../../Components/interface/NavBar";
import styles from "./BuyDongle.module.css";
import image4 from "../../../asset/images/car.png";
import BottomNav from "../../../Components/BottomNav";
import "react-multi-carousel/lib/styles.css";
import ScanForm from "../../../Components/interface/ScanForm";
import { useDispatch, useSelector } from "react-redux";
import Images from "../../../utils/CarImages";
import VehicleDetails2 from "../../../Components/interface/VehicleDetails2";
import VehicleInfo from "../../../Components/customer-profile/VehicleInfo";
import CustomerScans from "../../../utils/api/CustomerScans";
import { dongleActions } from "../../../store/redux-store/dongle-store";
import Modal from "../../../Components/UI/Modal";
import HomeVehiclePapers from "../../../Components/interface/HomeVehiclePapers";
import RoutineMaintenance from "../../../Components/vehicle/RoutineMaintenance";
import marketting from "../../../asset/images/marketting/Mask.png";
import marketting2 from "../../../asset/images/marketting/Mask2.png";

function BuyDongle(props) {
  const [diagForm, setDiagForm] = useState(false);
  const [showVehicleDetails, setShowVehicleDetails] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  const [carState, setCarState] = useState("");

  const personalData = useSelector((data) => data.auth);

  // get index of vehicle to get current vehicle data
  const dongle = useSelector((data) => data.dongles);

  const liveMetrics = dongle.liveMetrics[0];

  const dispatch = useDispatch();

  // defining all functions
  const dongleDataCallback = useCallback(async () => {
    setInitLoading(true);
    try {
      const res = await CustomerScans.getDevices({
        id: personalData.generalLoginData[0].id,
        access_token: personalData.token,
      });
      dispatch(dongleActions.donglesInfo(res.data));
      setInitLoading(false);
    } catch (err) {
      setInitLoading(false);
    }
  }, [personalData.generalLoginData, personalData.token, dispatch]);

  const fetchBasicMetrics = useCallback(async () => {
    try {
      const res = await CustomerScans.scanVehiclesHandler({
        user: personalData.generalLoginData[0].id,
        device: dongle?.dongleData[0]?.dongle,
        access_token: personalData.token,
      });
      dispatch(dongleActions.getLiveMetrics(res.data.data));
    } catch (err) {
      setInitLoading(false);
    }
  }, [
    personalData.generalLoginData,
    personalData.token,
    dongle?.dongleData,
    dispatch,
  ]);

  useEffect(() => {
    if (dongle?.dongleData[0]?.dongle?.length !== 0) {
      //fetch basic metrics
      fetchBasicMetrics();
    }
  }, [fetchBasicMetrics, dongle?.dongleData]);

  const vehicle_data =
    personalData.generalLoginData[0]?.vehicle_data.length !== 0
      ? personalData.generalLoginData[0]?.vehicle_data
      : personalData.generalLoginData[0]?.vendor_vehicle_data;

  const carImage = Images?.find(
    (data) =>
      data.name?.toUpperCase() ===
      `${vehicle_data[dongle.currentVehicle]?.vehicle_year}-${vehicle_data[
        dongle.currentVehicle
      ]?.vehicle_make?.toUpperCase()}-${vehicle_data[
        dongle.currentVehicle
      ]?.vehicle_model?.toUpperCase()}`
  );

  let closeMenuHandler = () => {
    props.setShowMenu(false);
  };

  useEffect(() => {
    dispatch(dongleActions.clearDonglesInfo());
    dongleDataCallback();
  }, [dongleDataCallback, dispatch]);

  useEffect(() => {
    let intervalId;
    if (liveMetrics?.scan_data?.vehicle_live_data?.online === true) {
      fetchBasicMetrics(); // Initial fetch
      intervalId = setInterval(() => {
        fetchBasicMetrics(); // Fetch data every 30 seconds
      }, 30000); // 30 seconds in milliseconds
    }

    return () => {
      clearInterval(intervalId); // Clear interval when component unmounts
    };
  }, [liveMetrics?.scan_data?.vehicle_live_data?.online, fetchBasicMetrics]);

  // const backHandler = () => {
  //   setDongleErr(false);
  // };

  useEffect(() => {
    if (liveMetrics?.scan_data?.vehicle_live_data?.online !== true) {
      setCarState("Offline");
    } else if (
      liveMetrics?.scan_data?.vehicle_live_data?.online === true &&
      liveMetrics?.scan_data?.vehicle_live_data?.speed === 0
    ) {
      setCarState("Parked");
    } else {
      setCarState("Online");
    }
  }, [
    liveMetrics?.scan_data?.vehicle_live_data?.online,
    liveMetrics?.scan_data?.vehicle_live_data?.speed,
  ]);

  return (
    <section className={styles.buyDongle}>
      {/* {dongle?.dongleData[0]?.dongle === null && dongleErr && (
        <Modal
          title="No dongle device detected"
          message="To use this application. You need to get a dongle device. Contact Motormata to get one"
          accept="Back"
          back=""
          close={backHandler}
          confirm={backHandler}
        />
      )} */}
      {diagForm && <ScanForm setDiagForm={setDiagForm} />}
      <NavBar
        onlineStatus={liveMetrics?.scan_data?.vehicle_live_data?.online}
        title="Home"
        setShowMenu={props.setShowMenu}
      />
      {initLoading ? (
        <Modal message="" back="" accept="" loader="true" />
      ) : (
        <div className={styles.buyDongle__body} onClick={closeMenuHandler}>
          <div className={styles.buyDongle__bodyInner}>
            {" "}
            {/* {dongle?.dongleData[0]?.dongle === null && dongleErr && (
              <div className={styles.banner}>
                <p>
                  To use this application. You need to get a dongle device.
                  Contact Motormata to get one.
                </p>
              </div>
            )} */}
            {!props.registerVehicle && (
              <div>
                <div className={styles.carType}>
                  <div className={styles.buyDongle__wrapper}>
                    <img src={carImage?.image || image4} alt="car" />
                  </div>
                  <div className={styles.online_status}>
                    {props.onlineStatus ? (
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.9999 13.5999C8.88356 13.5999 9.5999 12.8836 9.5999 11.9999C9.5999 11.1162 8.88356 10.3999 7.9999 10.3999C7.11625 10.3999 6.3999 11.1162 6.3999 11.9999C6.3999 12.8836 7.11625 13.5999 7.9999 13.5999Z"
                          fill="#008069"
                        />
                        <path
                          d="M0.800049 5.91997C1.70093 4.81334 2.80886 3.92705 4.05025 3.31997C5.29163 2.71289 6.63804 2.39893 8.00005 2.39893C9.36205 2.39893 10.7085 2.71289 11.9499 3.31997C13.1912 3.92705 14.2992 4.81334 15.2 5.91997L14 7.03997C13.2493 6.09508 12.326 5.33832 11.2916 4.81997C10.2571 4.30162 9.13505 4.03354 8.00005 4.03354C6.86504 4.03354 5.74303 4.30162 4.70855 4.81997C3.67406 5.33832 2.75078 6.09508 2.00005 7.03997L0.800049 5.91997ZM3.76005 8.47997C4.28572 7.83026 4.94107 7.30807 5.68032 6.94986C6.41957 6.59164 7.2249 6.40606 8.04005 6.40606C8.8552 6.40606 9.66053 6.59164 10.3998 6.94986C11.139 7.30807 11.7944 7.83026 12.32 8.47997L11.2 9.59997C10.8252 9.10477 10.3503 8.7051 9.8103 8.43048C9.27029 8.15585 8.67899 8.01328 8.08005 8.01328C7.48111 8.01328 6.88981 8.15585 6.3498 8.43048C5.80979 8.7051 5.33486 9.10477 4.96005 9.59997L3.76005 8.47997Z"
                          fill="#008069"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.9999 13.5999C8.88356 13.5999 9.5999 12.8836 9.5999 11.9999C9.5999 11.1162 8.88356 10.3999 7.9999 10.3999C7.11625 10.3999 6.3999 11.1162 6.3999 11.9999C6.3999 12.8836 7.11625 13.5999 7.9999 13.5999Z"
                          fill="#8EA396"
                        />
                        <path
                          d="M0.800049 5.91997C1.70093 4.81334 2.80886 3.92705 4.05025 3.31997C5.29163 2.71289 6.63804 2.39893 8.00005 2.39893C9.36205 2.39893 10.7085 2.71289 11.9499 3.31997C13.1912 3.92705 14.2992 4.81334 15.2 5.91997L14 7.03997C13.2493 6.09508 12.326 5.33832 11.2916 4.81997C10.2571 4.30162 9.13505 4.03354 8.00005 4.03354C6.86504 4.03354 5.74303 4.30162 4.70855 4.81997C3.67406 5.33832 2.75078 6.09508 2.00005 7.03997L0.800049 5.91997ZM3.76005 8.47997C4.28572 7.83026 4.94107 7.30807 5.68032 6.94986C6.41957 6.59164 7.2249 6.40606 8.04005 6.40606C8.8552 6.40606 9.66053 6.59164 10.3998 6.94986C11.139 7.30807 11.7944 7.83026 12.32 8.47997L11.2 9.59997C10.8252 9.10477 10.3503 8.7051 9.8103 8.43048C9.27029 8.15585 8.67899 8.01328 8.08005 8.01328C7.48111 8.01328 6.88981 8.15585 6.3498 8.43048C5.80979 8.7051 5.33486 9.10477 4.96005 9.59997L3.76005 8.47997Z"
                          fill="#8EA396"
                        />
                      </svg>
                    )}
                    <p>{carState}</p>
                  </div>
                </div>
                <VehicleDetails2
                  showVehicleDetails={showVehicleDetails}
                  setShowVehicleDetails={setShowVehicleDetails}
                  vehicle_make={
                    vehicle_data[dongle.currentVehicle]?.vehicle_make
                  }
                  vehicle_model={
                    vehicle_data[dongle.currentVehicle]?.vehicle_model
                  }
                  vehicle_year={
                    vehicle_data[dongle.currentVehicle]?.vehicle_year
                  }
                  vin="Not Available"
                  plate_number={
                    vehicle_data[dongle.currentVehicle]?.plate_number
                  }
                />
                {showVehicleDetails && (
                  <VehicleInfo info={vehicle_data[dongle.currentVehicle]} />
                )}
              </div>
            )}
          </div>

          <div>
            <HomeVehiclePapers />

            {personalData?.generalLoginData[0]?.dongles?.length !== 0 ? (
              <RoutineMaintenance />
            ) : (
              <div className={styles.home_marketting}>
                <div className={styles.home_marketting_inner}>
                  <div className={styles.home_marketting_inner_text}>
                    <p>Get Started with</p>
                    <h1>Smartcar Mobile App</h1>
                    <p>An Automobile App</p>
                  </div>
                  <div className={styles.home_marketting_inner_image}>
                    <div className={styles.home_marketting_inner_image_wrapper}>
                      <img src={marketting} alt="marketting" />
                    </div>
                  </div>
                  <div></div>
                </div>
                <div className={styles.home_marketting_inner}>
                  <div className={styles.home_marketting_inner_text}>
                    <p>Get Started with</p>
                    <h1>Smartcar Mobile App</h1>
                    <p>An Automobile App</p>
                  </div>
                  <div className={styles.home_marketting_inner_image}>
                    <div className={styles.home_marketting_inner_image_wrapper}>
                      <img src={marketting2} alt="marketting" />
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <BottomNav />
    </section>
  );
}

export default BuyDongle;
