import React, { useState } from "react";
import styles from "./FilteredInput.module.css";

function FilteredInput(props) {
  const [clickedItem, setClickedItem] = useState(null);

  const clickedFilterHandler = (data, index) => {
    let check = clickedItem === index ? "" : index;
    let dataToBeSent = clickedItem === index ? "" : data;
    setClickedItem(check);
    props.setModal(true);
    props.filter(dataToBeSent);
  };

  return (
    <div className={styles.head}>
      <div className={styles.filter_head}>
        <p>{props.title}</p>
      </div>
      <div className={styles.filterOptions}>
        {props.listNames.map((data, index) => (
          <div
            key={index}
            className={styles.filterCard}
            onClick={() => clickedFilterHandler(data, index)}
          >
            <p style={{ color: clickedItem !== index ? "" : "#168912" }}>
              {data}
            </p>
            {clickedItem !== index ? (
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.3333 1H3.66667C2.19391 1 1 2.19391 1 3.66667V10.3333C1 11.8061 2.19391 13 3.66667 13H10.3333C11.8061 13 13 11.8061 13 10.3333V3.66667C13 2.19391 11.8061 1 10.3333 1Z"
                  stroke="#8EA396"
                  stroke-width="1.33333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5 6.99935L6.5 8.33268L9 5.66602"
                  stroke="#8EA396"
                  stroke-width="1.33333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.83333 0H3.16667C1.69391 0 0.5 1.19391 0.5 2.66667V9.33333C0.5 10.8061 1.69391 12 3.16667 12H9.83333C11.3061 12 12.5 10.8061 12.5 9.33333V2.66667C12.5 1.19391 11.3061 0 9.83333 0Z"
                  fill="#168912"
                />
                <path
                  d="M4.5 5.99935L6 7.33268L8.5 4.66602"
                  stroke="white"
                  stroke-width="1.33333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default FilteredInput;
