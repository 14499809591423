let states = [
  "Select your State",
  "FCT - Abuja",
  "Lagos",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Rivers",
];
export default states;
