import React from "react";
import { useSelector } from "react-redux";
import Transactions from "../../Components/customer-profile/Transactions";
import styles from "./Account.module.css";
function DebitTransaction(props) {
  const wallet = useSelector((data) => data.auth.wallet);
  const DebitTransact = wallet?.results?.filter((data, index) => {
    return data?.transaction_type === "DEBIT";
  });
  return (
    <div className={styles.transactionList_wrapper}>
      {props.loading && (
        <div>
          <h4>Loading....</h4>
        </div>
      )}
      {!props.loading && (
        <div>
          {" "}
          {DebitTransact?.map((data, index) => {
            const creditData =
              data?.transaction_type === "DEBIT" ? false : true;
            return <Transactions credit={creditData} product={data} />;
          })}
        </div>
      )}
    </div>
  );
}

export default DebitTransaction;
