import React from "react";
import styles from "./Analysis.module.css";
import DashBoardHeader from "../../../Components/interface/DashBoardHeader";
import { Link } from "react-router-dom";

function Analysis() {
  return (
    <div className={styles.head}>
      <DashBoardHeader title="MM Analysis" />
      <div>
        <div className={styles.carStatus}>
          <div className={styles.carStatus__inner}>
            <h4>Here are the error codes diagnosed </h4>
            <div className={styles.refresh}>
              <h4>Refresh</h4>
            </div>
          </div>
          <div className={styles.diagnose__wrapper}>
            <div className={styles.diagnose__wrapper__inner}>
              <div className={styles.error}>
                <p> &#62; P063500 -</p>
                <p className={styles.desc}>Electrical power steering m..</p>
              </div>
              <div>
                <Link to="">
                  <p>View</p>
                </Link>
              </div>
            </div>

            <div className={styles.diagnose__wrapper__inner}>
              <div className={styles.error}>
                <p> &#62; P063500 -</p>
                <p className={styles.desc}>Electrical power steering m..</p>
              </div>
              <div>
                <Link to="">
                  <p>View</p>
                </Link>
              </div>
            </div>

            <div className={styles.diagnose__wrapper__inner}>
              <div className={styles.error}>
                <p> &#62; P063500 -</p>
                <p className={styles.desc}>Electrical power steering m..</p>
              </div>
              <div>
                <Link to="">
                  <p>View</p>
                </Link>
              </div>
            </div>

            <div className={styles.diagnose__wrapper__inner}>
              <div className={styles.error}>
                <p> &#62; P063500 -</p>
                <p className={styles.desc}>Electrical power steering m..</p>
              </div>
              <div>
                <Link to="">
                  <p>View</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Analysis;
