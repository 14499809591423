import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./Insurance.module.css";
import placeholder from "../../asset/images/imgPlaceholder.png";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../store/redux-store/Index";
import Button from "../../Components/interface/Button";
import Input from "../../Components/interface/Input";
import Webcam from "react-webcam";

const initialValues = {
  name: "",
  number: "",
  plateNumber: "",
};
const initialBoolenValues = {
  toggle: false,
  nameInvalid: false,
  phoneNumberInvalid: false,
  formIsValid: false,
  validPlateNumber: false,
};

function InsuranceClaims() {
  const [values, setValues] = useState(initialValues);
  const [boolen, setBoolen] = useState(initialBoolenValues);
  const [maxUpload, setMaxUpload] = useState(false);
  const webcamRef = useRef(null);

  const dispatch = useDispatch();

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    dispatch(authActions.uploadCarInsurancePicture(imageSrc));
  }, [webcamRef, dispatch]);

  const videoConstraints = {
    width: { min: 480 },
    height: { min: 720 },
    aspectRatio: 0.6666666667,
    facingMode: { exact: "environment" },
  };

  const uploadedImages = useSelector(
    (state) => state.auth.uploadedImagesStorageClaims
  );

  useEffect(() => {
    if (uploadedImages.length >= 4) {
      setMaxUpload(true);
    } else {
      setMaxUpload(false);
    }
  }, [uploadedImages.length]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  useEffect(() => {
    if (
      values.name.trim().length >= 1 &&
      values.number.trim().length >= 11 &&
      values.plateNumber.trim().length >= 2
    ) {
      setBoolen({ formIsValid: true });
    } else {
      setBoolen({ formIsValid: false });
    }
  }, [values.number, values.name, values.plateNumber]);
  // const pictureChangeHandler = (e) => {
  //   let picture = e.target.files[0];
  //   setPic(picture);
  // };

  //   Function to get url of the picture using file reader api //
  // useEffect(() => {
  //   let fileReader,
  //     isCancel = false;
  //   if (pic) {
  //     fileReader = new FileReader();
  //     fileReader.onload = (e) => {
  //       const { result } = e.target;
  //       if (!isCancel) {
  //         dispatch(authActions.uploadCarInsuranceClaimPicture(result));
  //       }
  //     };
  //     fileReader.readAsDataURL(pic);
  //   }
  //   return () => {
  //     isCancel = true;
  //     if (fileReader && fileReader.readyState === 1) {
  //       fileReader.abort();
  //     }
  //   };
  // }, [pic, dispatch]);

  const clearPicturesHandler = () => {
    dispatch(authActions.clearUploadCarInsuranceClaimPicture());
  };

  let numberInputBlur = () => {
    if (values.number.trim().length === 0) {
      setBoolen({ ...boolen, phoneNumberInvalid: true });
    } else {
      setBoolen({ ...boolen, phoneNumberInvalid: false });
    }
  };

  let nameInputBlur = () => {
    if (values.name.trim().length === 0) {
      setBoolen({ ...boolen, nameInvalid: true });
    } else {
      setBoolen({ ...boolen, nameInvalid: false });
    }
  };
  const image = uploadedImages.length !== 0 ? uploadedImages[0] : placeholder;
  const image2 = uploadedImages.length !== 0 ? uploadedImages[1] : placeholder;
  const image3 = uploadedImages.length !== 0 ? uploadedImages[2] : placeholder;
  const image4 = uploadedImages.length !== 0 ? uploadedImages[3] : placeholder;
  return (
    <div>
      <div className={styles.register_head}>
        <p>Take four (4) shots of the four sides of the vehicle.</p>
      </div>
      {!maxUpload && (
        <div className={styles.imageUploadWrapper}>
          <Webcam
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            mirrored={false}
            videoConstraints={videoConstraints}
            height={300}
            width={280}
          />
          <Button onClick={capture}>Capture</Button>
        </div>
      )}
      <div className={styles.videoUpload}>
        <label onClick={clearPicturesHandler}>
          <p>Clear Pictures</p>
          <svg
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.677 3.888C20.1573 1.368 16.8457 0 13.2822 0C9.75469 0 6.40714 1.368 3.88748 3.888C-1.29583 9.072 -1.29583 17.496 3.88748 22.644C6.40714 25.164 9.71869 26.532 13.2822 26.532C16.8097 26.532 20.1573 25.164 22.677 22.644C27.8603 17.496 27.8603 9.072 22.677 3.888ZM22.353 22.356C19.9413 24.768 16.7018 26.1 13.2822 26.1C9.86267 26.1 6.62311 24.768 4.21143 22.356C-0.791893 17.352 -0.791893 9.216 4.21143 4.212C6.62311 1.8 9.86267 0.468 13.2822 0.468C16.7018 0.468 19.9413 1.8 22.353 4.212C27.3563 9.216 27.3563 17.352 22.353 22.356ZM17.1337 9.756L13.4982 13.392L17.1337 17.028C17.2057 17.1 17.2057 17.244 17.1337 17.352C17.0977 17.388 17.0257 17.424 16.9897 17.424C16.9177 17.424 16.8817 17.388 16.8457 17.352L13.1742 13.716L9.53872 17.352C9.50272 17.388 9.43073 17.424 9.39474 17.424C9.32275 17.424 9.28675 17.388 9.25076 17.352C9.17877 17.28 9.17877 17.136 9.25076 17.028L12.8863 13.392L9.21476 9.756C9.14277 9.684 9.14277 9.54 9.21476 9.432C9.28675 9.36 9.43073 9.36 9.53872 9.432L13.1742 13.068L16.8097 9.432C16.8817 9.36 17.0257 9.36 17.1337 9.432C17.2057 9.54 17.2057 9.684 17.1337 9.756Z"
              fill="#008069"
            />
          </svg>
        </label>
      </div>
      <div className={styles.uploadedImageWrapper}>
        <div className={styles.uploadedImageWrapper_container}>
          <div className={styles.uploadedImageWrapper_inner}>
            <img src={image} alt="" />
          </div>
        </div>

        <div className={styles.uploadedImageWrapper_container}>
          <div className={styles.uploadedImageWrapper_inner}>
            <img src={image2} alt="" />
          </div>
        </div>

        <div className={styles.uploadedImageWrapper_container}>
          <div className={styles.uploadedImageWrapper_inner}>
            <img src={image3} alt="" />
          </div>
        </div>

        <div className={styles.uploadedImageWrapper_container}>
          <div className={styles.uploadedImageWrapper_inner}>
            <img src={image4} alt="" />
          </div>
        </div>
      </div>
      <div className={styles.plateNumber_input}>
        <Input
          label="Third party’s full name"
          onBlur={nameInputBlur}
          invalid={boolen.nameInvalid}
          onChange={handleInputChange}
          value={values.name}
          type="name"
          placeholder="Third party’s full name"
          name="name"
        />
        <Input
          label="Third party’s phone number."
          onBlur={numberInputBlur}
          invalid={boolen.phoneNumberInvalid}
          onChange={handleInputChange}
          value={values.number}
          type="tel"
          placeholder="Enter phone number"
          name="number"
        />
        <Input
          type="text"
          value={values.plateNumber}
          label="Third party’s plate number"
          invalid={boolen.validPlateNumber}
          onBlur={numberInputBlur}
          onChange={handleInputChange}
          placeholder="Third party’s plate number"
          name="plateNumber"
        />
      </div>
      <div>
        <Button disabled={!boolen.formIsValid}>Proceed</Button>
      </div>
      {/* <video src={fileVideoDataURL} controls></video> */}
    </div>
  );
}

export default InsuranceClaims;
