import React, { useState } from "react";
import NavBar from "../../../Components/interface/NavBar";
import InsuranceMenu from "../../Insurance/InsuranceMenu";
import SearchInput2 from "../../../Components/inputs/SearchInput2";
import SparepartsList from "./SparepartsList";
import styles from "./Services.module.css";
import BottomNav from "../../../Components/BottomNav";

function SpareParts(props) {
  const [searchInput, setSearchInput] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  // const [result, setResult] = useState(false);
  const searchInputChangeHandler = (e) => {
    setSearchInput(e.target.value);
  };

  const toggleFilterHandler = () => {
    setShowFilter((p) => !p);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    console.log(searchInput);
    // setResult(true);
    setSearchInput("");
  };
  return (
    <section>
      <NavBar title="Maintenance" setShowMenu={props.setShowMenu} />
      <div className={styles.head}>
        <div className={styles.main_hero}>
          <InsuranceMenu
            first="Services"
            first_link="services"
            second="Spare Parts"
            second_link="spare-parts"
          />
          <form onSubmit={formSubmitHandler} className={styles.form}>
            <div className={styles.search_input}>
              <SearchInput2
                value={searchInput}
                onChange={searchInputChangeHandler}
                name="search"
                placeholder="Search"
              />
            </div>
            <div className={styles.search_filter} onClick={toggleFilterHandler}>
              <p>Filter</p>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.6666 2V4.66667V2ZM12.6666 14V7.33333V14ZM7.99992 2V10V2ZM7.99992 14V12.6667V14ZM3.33325 2V3.33333V2ZM3.33325 14V6V14Z"
                  fill="#008069"
                />
                <path
                  d="M3.33325 14V6M12.6666 2V4.66667V2ZM12.6666 14V7.33333V14ZM7.99992 2V10V2ZM7.99992 14V12.6667V14ZM3.33325 2V3.33333V2Z"
                  stroke="#008069"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <path
                  d="M11.3333 6.0013C11.3333 6.73768 11.9303 7.33464 12.6667 7.33464C13.403 7.33464 14 6.73768 14 6.0013C14 5.26492 13.403 4.66797 12.6667 4.66797C11.9303 4.66797 11.3333 5.26492 11.3333 6.0013Z"
                  stroke="#008069"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <path
                  d="M6.66659 11.3333C6.66659 12.0697 7.26354 12.6667 7.99992 12.6667C8.7363 12.6667 9.33325 12.0697 9.33325 11.3333C9.33325 10.597 8.7363 10 7.99992 10C7.26354 10 6.66659 10.597 6.66659 11.3333Z"
                  stroke="#008069"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <path
                  d="M2.00008 4.66536C2.00008 5.40174 2.59703 5.9987 3.33341 5.9987C4.06979 5.9987 4.66675 5.40174 4.66675 4.66536C4.66675 3.92898 4.06979 3.33203 3.33341 3.33203C2.59703 3.33203 2.00008 3.92898 2.00008 4.66536Z"
                  stroke="#008069"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
            </div>
          </form>
          <SparepartsList
            // searchInput={searchInput}
            showFilter={showFilter}
            // setSearchInput={setSearchInput}
            // result={result}
          />
        </div>
      </div>

      <BottomNav />
    </section>
  );
}

export default SpareParts;
