import React, { useEffect, useState } from "react";
import styles from "./Report.module.css";
import image from "../../asset/images/car.png";
import { useSelector } from "react-redux";
import Images from "../../utils/CarImages";
import VehicleFaultCodes from "../vehicle/VehicleFaultCodes";
import NavBar from "../interface/NavBar";
import BottomNav from "../BottomNav";
import LiveMetricsReport2 from "./LiveMetrics2";
import VehicleInformation2 from "../UI/VehicleInformation2";
import Contact from "../interface/Contact";
import CustomerScans from "../../utils/api/CustomerScans";

function ReportPage2(props) {
  const [vehicleInfo, setVehiclInfo] = useState(null);

  const personalData = useSelector((data) => data.auth);

  const dongle = useSelector((data) => data.dongles);

  const liveMetrics = dongle.liveMetrics[0];

  useEffect(() => {
    const fetchYmmData = async () => {
      try {
        const ymmRes = await CustomerScans.vinDecoder({
          token: personalData.token,
          vin: liveMetrics?.scan_data?.vehicle_vin,
        });
        setVehiclInfo(ymmRes.data);
      } catch (err) {
        console.log(err?.response?.error);
      }
    };
    fetchYmmData();
  }, [liveMetrics?.scan_data?.vehicle_vin, personalData.token]);

  const carImage = Images?.find(
    (data) =>
      data.name?.toUpperCase() ===
      `${
        vehicleInfo?.vehicle_year
      }-${vehicleInfo?.vehicle_make?.toUpperCase()}-${vehicleInfo?.vehicle_model?.toUpperCase()}`
  );

  const vehicle_vin = liveMetrics?.scan_data?.vehicle_vin
    ? liveMetrics?.scan_data?.vehicle_vin
    : "No vin";
  return (
    <div>
      <NavBar title="Report" setShowMenu={props.setShowMenu} />
      <div className={styles.head}>
        <div className={styles.user_summary}>
          <h3>SN: {liveMetrics?.reference}</h3>
          <p>
            Test Time: {liveMetrics?.created_at?.slice(0, 10)}{" "}
            {liveMetrics?.created_at?.slice(11, 16)}
          </p>
          <p>Customer ID: {liveMetrics?.customer_info?.id}</p>
          <p>Customers Contact: {personalData?.generalLoginData[0]?.email}</p>
        </div>

        <div className={styles.carType}>
          <div className={styles.buyDongle__wrapper}>
            <img src={carImage?.image || image} alt="car" />
          </div>
        </div>
        <div className={styles.vehicleInfo}>
          <VehicleInformation2
            // vahicle_body={ personalData.generalLoginData[0]?.vehicle_data[
            //   dongle.currentVehicle}
            vehicle_make={vehicleInfo?.vehicle_make}
            vehicle_model={vehicleInfo?.vehicle_model}
            vehicle_year={vehicleInfo?.vehicle_year}
            vin={vehicle_vin}
            plate_number={
              personalData.generalLoginData[0]?.vehicle_data[
                dongle.currentVehicle
              ]?.plate_number
            }
          />
        </div>
        <div className={styles.vehicleInfo}>
          <div>
            <LiveMetricsReport2 metrics={liveMetrics} />
          </div>
          <div className={styles.system_codes}>
            {liveMetrics?.scan_data?.vehicle_errors?.length >= 1 && (
              <div className={styles.system_codes_head}>
                <h3>System Fault Code</h3>
                <p>
                  Here are the error codes diagnosed as at the time of last
                  test.
                </p>{" "}
                <div className={styles.error_wrapper}>
                  {liveMetrics?.scan_data?.vehicle_errors?.map(
                    (data, index) => (
                      <VehicleFaultCodes
                        key={index}
                        title={data.code}
                        liveMetrics={liveMetrics}
                        description={data.description}
                      />
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <Contact />
        </div>
      </div>
      <BottomNav />
    </div>
  );
}

export default ReportPage2;
