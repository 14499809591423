import React from "react";
import { Outlet } from "react-router";
import BottomNav from "../../Components/BottomNav";
import NavBar from "../../Components/interface/NavBar";
import styles from "./Account.module.css";

function Account(props) {
  return (
    <div>
      <NavBar title="Wallet" setShowMenu={props.setShowMenu} />
      <div className={styles.head}>
        <div className={styles.main_hero}>
          <Outlet />
        </div>
      </div>
      <BottomNav />
    </div>
  );
}

export default Account;
