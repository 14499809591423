import React, { useEffect, useState } from "react";
import image4 from "../../../asset/images/car.png";
import styles from "./Home.module.css";
import Button from "../../../Components/interface/Button";
// import MaintenanceTip from "../../../Components/interface/MaintenanceTip";
import LiveMetricsReport from "../../../Components/interface/LiveMetricsReport";
import Images from "../../../utils/CarImages";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../store/redux-store/Index";
import Modal from "../../../Components/UI/Modal";
import Modal3 from "../../../Components/UI/Modal";
import Modal2 from "../../../Components/UI/Modal2";
import copy from "copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OnlineStatus from "../../../Components/interface/OnlineStatus";
import ScanForm from "../../../Components/interface/ScanForm";
import VehicleDetails2 from "../../../Components/interface/VehicleDetails2";

function Home() {
  const [diagReport, setDiagReport] = useState(false);
  const [formIsValid, setFormIsValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [userLogged, setUserLogged] = useState(false);
  // const [activePage, setActivePage] = useState(false);
  let [time, setTime] = useState([]);
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [err, setErr] = useState([]);
  const [linkId, setLinkId] = useState("");
  const [link, setLink] = useState("");
  // const [errInput, setErrInput] = useState([]);
  const [offlineModal, setOfflineModal] = useState(false);
  const [onlineStatus, setOnineStatus] = useState("");
  const [vinData, setVinData] = useState([]);
  const [modal2, setModal2] = useState(false);
  const [confirmForm, setConfirmForm] = useState(false);
  const [information, setInformation] = useState({});
  const [diagForm, setDiagForm] = useState(false);
  let deviceID = useSelector((state) => state.auth.deviceID);
  let vin = useSelector((state) => state.auth.vin);
  let vehicleDetails = useSelector((state) => state.auth.vehicleDetailsAlt);
  let plate_no = useSelector((state) => state.auth.plateNumber2);
  let loggedIn = useSelector((data) => data.auth.userLoggedInDevice);
  let personalData = useSelector((data) => data.auth.generalLoginData);
  let details = useSelector((state) => state.auth);
  let dispatch = useDispatch();

  useEffect(() => {
    const timer = setInterval(() => {
      let date = new Date();
      let month = date.getUTCMonth() + 1; //months from 1-12
      let day = date.getUTCDate();
      let year = date.getUTCFullYear();
      let h = date.toLocaleString("en-US", {
        hour: "numeric",
        hour12: false,
        timeZone: "Africa/Lagos",
      });
      let s = date.toLocaleString("en-US", {
        second: "2-digit",
        timeZone: "Africa/Lagos",
      });
      let m = date.toLocaleString("en-US", {
        minute: "2-digit",
        timeZone: "Africa/Lagos",
      });
      m = m < 10 ? "0" + m : m;
      s = s < 10 ? "0" + s : s;
      setTime(`${year}-${month}-${day} ${h}:${m}:${s}`);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [time]);

  useEffect(() => {
    if (loggedIn) {
      setUserLogged(true);
      setTimeout(() => {
        dispatch(authActions.loggedOut());
      }, 300000);
    } else {
      setUserLogged(false);
    }
  }, [loggedIn, dispatch]);

  useEffect(() => {
    setLink(`https://smartscan.motormata.com/report/${linkId}`);
    if (!diagReport) {
      setInformation({
        title: "Diagnosis Charges",
        message:
          "Note, charges of ₦1,000.00 would be deducted from your wallet for every scan and diagnosis report of your vehicle.",
        back: "Back",
        accept: "Accept",
      });
    }
    if (diagReport) {
      setInformation({
        title: "Report generated",
        message: `The report has been generated successfully, the link to the report is also available for use. ${link}. Click "copy" to copy report link`,
        back: "Share",
        accept: "Copy",
      });
    }
  }, [diagReport, linkId, link]);
  useEffect(() => {
    let fetchUserData = async () => {
      let url2 = `https://bpms.motormata.com/api/v1/noauth/cusinfo/${details.plateNumber2}`;
      let res = await fetch(url2, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      let data1 = await res.json();
      if (res.ok) {
        setUserData(data1);
      } else {
        console.log(data1);
      }
    };
    fetchUserData();
  }, [details.plateNumber2]);
  let diagnoseDataHandler = () => {
    if (onlineStatus === "Offline" || onlineStatus === "Parked") {
      setOfflineModal(true);
    } else {
      setConfirmForm(true);
    }
  };

  useEffect(() => {
    let liveData = async () => {
      let url = `https://api.dev.motormata.com/api/v1/dongle/vehicle/live-data/${deviceID}`;
      setLoading(true);
      setFormIsValid(false);
      let res = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      let data1 = await res.json();
      setLoading(false);
      setFormIsValid(true);
      if (res.ok) {
        dispatch(authActions.resetLiveMetrics());
        dispatch(authActions.dongleID(deviceID));
        dispatch(authActions.error(data1));
        dispatch(authActions.vin(data1.vehicle_location.vehicle_vin));
        dispatch(authActions.metrics(data1));
        setData(data1);
        setErr(data1.vehicle_errors);
      } else {
        console.log(data1);
      }
      if (
        data1?.vehicle_live_data?.engine_speed > 0 &&
        data1?.vehicle_live_data?.speed > 0
      ) {
        setOnineStatus("Online");
      } else if (
        data1?.vehicle_live_data?.engine_speed === 0 &&
        data1?.vehicle_live_data?.speed === 0
      ) {
        setOnineStatus("Parked");
      } else if (
        data1?.vehicle_live_data?.engine_speed > 0 &&
        data1?.vehicle_live_data?.speed === 0
      ) {
        setOnineStatus("Idling");
      } else if (data1?.vehicle_live_data?.online === false) {
        setOnineStatus("Offline");
      }
    };
    liveData();

    const timer = setInterval(() => {
      liveData();
    }, 60000);
    return () => {
      clearInterval(timer);
    };
  }, [deviceID, dispatch]);

  useEffect(() => {
    let vinHandler = async () => {
      let url = `https://api.dev.motormata.com/api/v1/dongle/vindecoder/${vin}`;
      let res = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      let data = await res.json();
      if (res.ok) {
        dispatch(authActions.resetVehicleData());
        dispatch(authActions.details(data));
        setVinData(data);
      } else {
        console.log(data);
      }
    };
    vinHandler();
  }, [vin, dispatch]);
  useEffect(() => {
    let error = async () => {
      let url = `https://bpms.motormata.com/api/v1/dongle/vehicle/errors/${err}`;
      let res = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      let data = await res.json();
      if (res.ok) {
        // setErrInput(data);
      } else {
        console.log(data);
      }
    };
    error();
  }, [err]);

  // function truncate(input) {
  //   if (input.length > 5) {
  //     return input.substring(0, 23) + "...";
  //   }
  //   return input;
  // }

  let carImage = Images?.find(
    (data, index) =>
      data.name?.toUpperCase() ===
        `${
          vinData.vehicle_year
        }-${vinData.vehicle_make?.toUpperCase()}-${vinData.vehicle_model?.toUpperCase()}` ||
      data.name?.toUpperCase() ===
        `${vehicleDetails[0]?.vehicleYear2?.toUpperCase()}-${vehicleDetails[0]?.vehicleName2?.toUpperCase()}-${vehicleDetails[0]?.vehicleModel2?.toUpperCase()}` ||
      data.name?.toUpperCase() ===
        `${
          personalData[0]?.vehicle_data[0]?.vehicle_year
        }-${personalData[0]?.vehicle_data[0]?.vehicle_make?.toUpperCase()}-${personalData[0]?.vehicle_data[0]?.vehicle_model?.toUpperCase()}`
  );

  const proceedHandler = () => {
    if (information.accept === "Accept") {
      setLoadingModal(true);
      setTimeout(() => {
        setDiagReport(true);
        setLoadingModal(false);
      }, 5000);
      setFormIsValid(false);
    }
    setConfirmForm(false);
    if (diagReport) {
      setModal2(true);
      copy(link);
    }
  };
  const backHandler = () => {
    setConfirmForm(false);
    setOfflineModal(false);
    setModal2(false);
  };

  const proceedHandlerFinal = () => {
    setModal2(false);
  };

  const generateReportHandler = () => {
    let url2 = `https://bpms.motormata.com/api/v1/dongle/report/post`;
    let postReport = async () => {
      setLoading(true);
      let response = await fetch(url2, {
        method: "POST",
        body: JSON.stringify({
          plate_no: details.plateNumber2 || details.plateNumber,
          test_time: time,
          customer_id: details.generalLoginData[0].id,
          customer_contact: details.generalLoginData[0].email,
          year: details.vehicleDetails2[0]?.vehicleYear,
          make: details.vehicleDetails2[0]?.vehicleName,
          model: details.vehicleDetails2[0]?.vehicleModel,
          vin: details.vin || "Not available",
          engine_size: "No data",
          odometer: details.liveMetrics[0].milage,
          vehicle_software_version: "No data",
          diagnostic: "No data",
          insurance: details.vehicleDetailsAlt[0].expiryDate,
          vehicle_license: userData.vehicle_license[0].expiry_date,
          road: userData.road,
          fine: "",
          battery: details.liveMetrics[0].pressure,
          mileage: "",
          rpm: details.liveMetrics[0].engine_speed,
          temperature: details.liveMetrics[0].coolant_temperature,
          engine_load: details.liveMetrics[0].engine_load,
          av_speed: details.liveMetrics[0].speed,
          fuel_consumption_rate:
            details.liveMetrics[0].current_fuel_consumption_rate,
          fuel_level: details.liveMetrics[0].remaining_fuel,
          error_codes: `${details.errorDetails}`,
          phone_no: "No Phone",
          email: "" || details.generalLoginData[0].email,
          longitude: details.liveMetrics[0].longitude,
          latitude: details.liveMetrics[0].latitude,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const reportData = await response.json();
      if (response.ok) {
        console.log(reportData.id);
        setLinkId(reportData.id);
        setConfirmForm(true);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(`Something went wrong 😢.try again later`, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setFormIsValid(true);
        setLoading(false);
      }
    };
    postReport();
  };
  const diagnoseActivateHandler = () => {
    setDiagForm(true);
  };

  let text = loading ? "Loading..." : "Generate Report";
  const color = userLogged ? "#0AB34E" : "#8EA396";
  const bckColor = userLogged ? "#E2FBD780" : "#83C91D0D";

  return (
    <section className={styles.subscription}>
      {confirmForm && (
        <Modal2
          title={information?.title}
          message={information?.message}
          back={information?.back}
          accept={information?.accept}
          close={backHandler}
          confirm={proceedHandler}
        />
      )}
      {modal2 && (
        <Modal2
          title="Copied successfully"
          message="You can now paste the link to the report to your customer’s whatsapp or share via sms"
          back="Ok"
          accept="Goto contact list"
          close={backHandler}
          confirm={proceedHandlerFinal}
        />
      )}
      {loadingModal && (
        <Modal
          title="Diagnosis"
          message="You’re almost done! 
Your car is under diagnosis"
          back=""
          accept=""
        />
      )}
      {offlineModal && (
        <Modal3
          title="Confirm!"
          message="Confirm that the smartcar dongle device is connected to your vehicle."
          close={backHandler}
          back=""
          accept="Close"
        />
      )}
      <ToastContainer />
      {/* {!activePage && !diagForm && <ScanForm setDiagForm={setDiagForm} />} */}
      {diagForm && (
        <ScanForm setUserLogged={setUserLogged} setDiagForm={setDiagForm} />
      )}
      {/* {activePage && ( */}
      <div className={styles.subscription__body}>
        {/* <div className={styles.wallet}>
          <div className={styles.wallet_ballance}>
            <h3>₦ 32,340.00 </h3>
            <p>Wallet balance</p>
          </div>
          <div className={styles.time}>
            <p>15-10-2022</p>
            <p>10:44:35 AM</p>
          </div>
        </div> */}
        <div className={styles.subscription__bodyInner}>
          <div className={styles.carType}>
            <div className={styles.subscription__wrapper}>
              <img src={carImage?.image || image4} alt="dongle" />
            </div>
            <OnlineStatus onlineStatus={onlineStatus} />
          </div>
          <VehicleDetails2
            vehicle_make={
              vinData.vehicle_make ||
              vehicleDetails[0]?.vehicleName2 ||
              personalData[0]?.vehicle_data[0]?.vehicle_make
            }
            vehicle_year={
              vinData.vehicle_year ||
              vehicleDetails[0]?.vehicleYear2 ||
              personalData[0]?.vehicle_data[0]?.vehicle_year
            }
            vehicle_model={
              vinData.vehicle_model ||
              vehicleDetails[0]?.vehicleModel2 ||
              personalData[0]?.vehicle_data[0]?.vehicle_model
            }
            vin={vinData.vehicle_vin || "Not available"}
            plate_number={
              plate_no || personalData[0]?.vehicle_data[0]?.plate_number
            }
          />
        </div>
        <section className={styles.diagnose}>
          <LiveMetricsReport
            color={color}
            bckColor={bckColor}
            reportData={data.vehicle_live_data}
          />
        </section>
        <section className={styles.diagnose}>
          <h4>Diagnosis</h4>
          <h5>Diagnose your car now to know your car maintenance status</h5>

          {!diagReport && (
            <div>
              {userLogged && (
                <div className={styles.button}>
                  <Button disabled={!formIsValid} onClick={diagnoseDataHandler}>
                    Diagnose
                  </Button>
                </div>
              )}
            </div>
          )}
          {!userLogged && (
            <div className={styles.button}>
              <Button onClick={diagnoseActivateHandler}>Diagnose</Button>
            </div>
          )}
          {diagReport && (
            <div className={styles.button}>
              <Button onClick={generateReportHandler}>{text}</Button>
            </div>
          )}
        </section>
        {/* <MaintenanceTip /> */}
      </div>
      {/* )} */}
    </section>
  );
}

export default Home;
