import React, { useEffect, useReducer, useState, useCallback } from "react";
import image4 from "../../../asset/images/car.png";
import BottomNav from "../../../Components/BottomNav";
import NavBar from "../../../Components/interface/NavBar";
import styles from "./Papers.module.css";
import Images from "../../../utils/CarImages";
import Button from "../../../Components/interface/Button";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../../Components/UI/Card";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { authActions } from "../../../store/redux-store/Index";
import Modal from "../../../Components/UI/Modal2";
import IframeModal from "../../../Components/interface/IframeModal";
import VehicleDetails from "../../../Components/interface/VehicleDetails";
import CarPapersAPi from "../../../utils/api/CarPapersApi";

let colorReducer = (state, action) => {
  if (action.type === "insurance") {
    return { color3: action.color, backColor3: action.backColor };
  }
  return {
    color3: "",
    backColor3: "",
  };
};
let colorReducer1 = (state, action) => {
  if (action.type === "license") {
    return { color1: action.color, backColor1: action.backColor };
  }
  return {
    color1: "",
    backColor1: "",
  };
};
let colorReducer2 = (state, action) => {
  if (action.type === "road") {
    return { color2: action.color, backColor2: action.backColor };
  }
  return {
    color2: "",
    backColor2: "",
  };
};

function Papers(props) {
  let [licenseToggle, setLicenseToggle] = useState(false);
  let [roadToggle, setRoadToggle] = useState(false);
  let [formIsValid, setFormIsValid] = useState(true);
  let [insuranceToggle, setInsuranceToggle] = useState(false);
  const [colorState, dispatchColor] = useReducer(colorReducer, {
    color3: "",
    backColor3: "",
  });
  const [colorState1, dispatchColor1] = useReducer(colorReducer1, {
    color1: "",
    backColor1: "",
  });
  const [colorState2, dispatchColor2] = useReducer(colorReducer2, {
    color2: "",
    backColor2: "",
  });
  const [vlAdd, setVlAdd] = useState(false);
  const [roadAdd, setRoadAdd] = useState(false);
  const [insuranceAdd, setInsuranceAdd] = useState(false);
  const [insuranceEx, setInsuranceEx] = useState(false);
  const [licenseEx, setLicenseEx] = useState(false);
  const [roadEx, setRoadEx] = useState(false);
  const [modal, setModal] = useState(false);
  const [engine, setEngine] = useState(true);
  const [time, setTime] = useState("");

  // get index of vehicle to get current vehicle data
  const vehicleIndex = useSelector((data) => data.dongles.currentVehicle);

  let personalData = useSelector((data) => data.auth.generalLoginData);

  let paperDetails = useSelector((data) => data.auth.papersDetails);
  // console.log(paperDetails);
  let dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      let date = new Date();
      let month = date.getUTCMonth() + 1; //months from 1-12
      let day = date.getUTCDate();
      let year = date.getUTCFullYear();
      let h = date.toLocaleString("en-US", {
        hour: "numeric",
        hour12: false,
        timeZone: "Africa/Lagos",
      });
      let s = date.toLocaleString("en-US", {
        second: "2-digit",
        timeZone: "Africa/Lagos",
      });
      let m = date.toLocaleString("en-US", {
        minute: "2-digit",
        timeZone: "Africa/Lagos",
      });
      m = m < 10 ? "0" + m : m;
      s = s < 10 ? "0" + s : s;
      setTime(`${year}-${month}-${day} ${h}:${m}:${s}`);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  let carImage = Images?.find(
    (data) =>
      data.name?.toUpperCase() ===
      `${
        personalData[0]?.vehicle_data[vehicleIndex]?.vehicle_year
      }-${personalData[0]?.vehicle_data[
        vehicleIndex
      ]?.vehicle_make?.toUpperCase()}-${personalData[0]?.vehicle_data[
        vehicleIndex
      ]?.vehicle_model?.toUpperCase()}`
  );
  useEffect(() => {
    if (licenseToggle === true) {
      dispatchColor1({
        type: "license",
        color: "#8EA396",
        backColor: "#F7F7F7",
      });
    }
    if (licenseToggle === false) {
      dispatchColor1({
        type: "license",
        color: "#168912",
        backColor: "#D7FBDB",
      });
    }
    if (roadToggle === true) {
      dispatchColor2({
        type: "road",
        color: "#8EA396",
        backColor: "#F7F7F7",
      });
    }
    if (roadToggle === false) {
      dispatchColor2({
        type: "road",
        color: "#168912",
        backColor: "#D7FBDB",
      });
    }
    if (insuranceToggle === true) {
      dispatchColor({
        type: "insurance",
        color: "#8EA396",
        backColor: "#F7F7F7",
      });
    }
    if (insuranceToggle === false) {
      dispatchColor({
        type: "insurance",
        color: "#168912",
        backColor: "#D7FBDB",
      });
    }
  }, [licenseToggle, insuranceToggle, roadToggle]);
  let getPapersApi = useCallback(() => {
    dispatch(authActions.paperDetailsClear());
    const getPapersHandler = async () => {
      try {
        const papersRes = await CarPapersAPi.getPapersForVehicle({
          plate_number:
            personalData[0]?.vehicle_data[vehicleIndex]?.plate_number,
        });
        if (papersRes.data !== "No Engine Found") {
          dispatch(authActions.paperDetails(papersRes.data));
        } else {
          setEngine(false);
        }
      } catch (err) {
        console.log(err?.response?.data?.error);
      }
    };
    getPapersHandler();
  }, [dispatch, personalData, vehicleIndex]);

  useEffect(() => {
    getPapersApi();
    dispatch(authActions.clearMagentoId());
  }, [getPapersApi, dispatch]);

  useEffect(() => {
    let paper = paperDetails[0];
    if (
      time > paperDetails[0]?.papers_record.insurance[0]?.updated_at ||
      paper?.papers_record?.insurance.length === 0
    ) {
      setInsuranceEx(true);
      setInsuranceAdd(false);
    } else if (
      time < paperDetails[0]?.papers_record.insurance[0]?.updated_at ||
      paper?.papers_record?.insurance.length !== 0
    ) {
      setInsuranceEx(false);
      setInsuranceAdd(true);
    }
    if (
      time > paperDetails[0]?.papers_record.vl[0]?.updated_at ||
      paper?.papers_record?.vl.length === 0
    ) {
      setLicenseEx(true);
      setVlAdd(false);
    } else if (
      time < paperDetails[0]?.papers_record.vl[0]?.updated_at ||
      paper?.papers_record?.vl.length !== 0
    ) {
      setLicenseEx(false);
      setVlAdd(true);
    }
    if (paperDetails[0]?.papers_record?.road[0]?.updated_at === undefined) {
      setRoadEx(true);
      setRoadAdd(false);
    } else if (
      paperDetails[0]?.papers_record?.road[0]?.updated_at !== undefined
    ) {
      setRoadEx(false);
      setRoadAdd(true);
    }
  }, [time, paperDetails]);

  useEffect(() => {
    if (
      licenseToggle === false ||
      roadToggle === false ||
      insuranceToggle === false
    ) {
      setFormIsValid(false);
    }
    if (
      licenseToggle === true ||
      roadToggle === true ||
      insuranceToggle === true
    ) {
      setFormIsValid(true);
    }
  }, [licenseToggle, roadToggle, insuranceToggle]);

  useEffect(() => {
    let paper = paperDetails[0];
    if (paper?.cart?.insurance !== 0) {
      setInsuranceToggle(true);
    } else if (paper?.cart?.insurance === 0) {
      setInsuranceToggle(false);
    }
    if (paper?.cart?.road !== 0) {
      setRoadToggle(true);
    } else if (paper?.cart?.road === 0) {
      setRoadToggle(false);
    }
    if (paper?.cart?.vl !== 0) {
      setLicenseToggle(true);
    } else if (paper?.cart?.vl === 0) {
      setLicenseToggle(false);
    }
  }, [paperDetails]);
  const licenseCartHandler = () => {
    if (!licenseToggle) {
      let addToCartHandler = async () => {
        try {
          const addedCartRes = await CarPapersAPi.addToCartHandler({
            plate_no: paperDetails[0].onboard[0].plate_no,
            cart_type: "Vehicle License",
            id: 3,
          });
          setLicenseToggle((p) => !p);
          return addedCartRes.data;
        } catch (err) {
          console.log(err?.response?.data?.error);
        }
      };
      addToCartHandler();
    }
    if (licenseToggle) {
      let removeCartHandler = async () => {
        try {
          const removedCartRes = await CarPapersAPi.removeFromCartHandler({
            plate_no: paperDetails[0].onboard[0].plate_no,
            cart_type: "Vehicle License",
            id: 3,
          });
          setLicenseToggle((p) => !p);
          return removedCartRes.data;
        } catch (err) {
          console.log(err?.response?.data?.error);
        }
      };
      removeCartHandler();
    }
  };
  const roadCartHandler = () => {
    if (!roadToggle) {
      let addToCartHandler = async () => {
        try {
          const addedCartRes = await CarPapersAPi.addToCartHandler({
            plate_no: paperDetails[0].onboard[0].plate_no,
            cart_type: "Road worthiness",
            id: 13,
          });
          setRoadToggle((p) => !p);
          return addedCartRes.data;
        } catch (err) {
          console.log(err?.response?.data?.error);
        }
      };
      addToCartHandler();
    }
    if (roadToggle) {
      let removeCartHandler = async () => {
        try {
          const addedCartRes = await CarPapersAPi.removeFromCartHandler({
            plate_no: paperDetails[0].onboard[0].plate_no,
            cart_type: "Road worthiness",
            id: 13,
          });
          setRoadToggle((p) => !p);
          return addedCartRes.data;
        } catch (err) {
          console.log(err?.response?.data?.error);
        }
      };
      removeCartHandler();
    }
  };
  const insuranceCartHandler = () => {
    if (!insuranceToggle) {
      let addToCartHandler = async () => {
        try {
          const addedCartRes = await CarPapersAPi.addToCartHandler({
            plate_no: paperDetails[0].onboard[0].plate_no,
            cart_type: "Third party insurance",
            id: 1,
          });
          setInsuranceToggle((p) => !p);
          return addedCartRes.data;
        } catch (err) {
          console.log(err?.response?.data?.error);
        }
      };
      addToCartHandler();
    }
    if (insuranceToggle) {
      let removeCartHandler = async () => {
        try {
          const addedCartRes = await CarPapersAPi.removeFromCartHandler({
            plate_no: paperDetails[0].onboard[0].plate_no,
            cart_type: "Third party insurance",
            id: 1,
          });
          setInsuranceToggle((p) => !p);
          return addedCartRes.data;
        } catch (err) {
          console.log(err?.response?.data?.error);
        }
      };
      removeCartHandler();
    }
  };
  const closeModalHandler = () => {
    setModal(false);
  };

  let exStatus = licenseEx ? "Expired" : "Active";
  let exStatusColor = licenseEx ? "#FD4D1E" : "#008069";

  let exStatusRoad = roadEx ? "Expired" : "Active";
  let exStatusRoadColor = roadEx ? "#FD4D1E" : "#008069";

  let exStatusInsurance = insuranceEx ? "Expired" : "Active";
  let exStatusInsuranceColor = insuranceEx ? "#FD4D1E" : "#008069";

  // useEffect(() => {
  const insurance =
    paperDetails[0]?.engine[0]?.insurances?.product_title ===
      "Third Party Insurance - Car/SUV" ||
    paperDetails[0]?.engine[0]?.insurances?.product_title ===
      "Third Party Insurance for Car/SUV"
      ? "Third Party Insurance"
      : "";

  const Road =
    paperDetails[0]?.engine[0]?.roads?.product_title ===
    "Road worthiness – SUV & CAR (from 2.0)"
      ? "Road worthiness"
      : "";

  const vehicleLicense =
    paperDetails[0]?.engine[0]?.vls?.product_title ===
      "Vehicle License Only - (2.0cc - 3.0cc)" ||
    paperDetails[0]?.engine[0]?.vls?.product_title ===
      "Vehicle License Only - (Above 3.0cc)"
      ? "Vehicle License"
      : "";
  // }, []);
  const backEngineHandler = () => {
    navigate("/");
  };
  const proceedHandlerFinal = () => {
    window.parent.open("https://wa.me/2348177676766");
  };
  return (
    <section>
      {modal && (
        <IframeModal closeModalHandler={closeModalHandler}>
          <div className={styles.modal}>
            <iframe
              src="demo_iframe.htm"
              name="iframe_a"
              height="400px"
              width="100%"
              title="Iframe Example"
            ></iframe>
          </div>
        </IframeModal>
      )}
      {!engine && (
        <Modal
          title="Engine Error"
          message="There is no engine set for this vehicle currently. Check again Later. "
          back="Close"
          close={backEngineHandler}
          confirm={proceedHandlerFinal}
          accept="Contact us on whatsapp for renewal"
        />
      )}
      <NavBar title="Vehicle Papers" setShowMenu={props.setShowMenu} />
      {engine && (
        <div className={styles.head}>
          <div className={styles.main_hero}>
            <div className={styles.carType}>
              <div className={styles.buyDongle__wrapper}>
                <img src={carImage?.image || image4} alt="dongle" />
              </div>
            </div>
            <VehicleDetails
              className={styles.vehicleDetails}
              vehicle_make={
                personalData[0]?.vehicle_data[vehicleIndex]?.vehicle_make
              }
              vehicle_year={
                personalData[0]?.vehicle_data[vehicleIndex]?.vehicle_year
              }
              vehicle_model={
                personalData[0]?.vehicle_data[vehicleIndex]?.vehicle_model
              }
              vin="Not Available"
              plate_number={
                personalData[0]?.vehicle_data[vehicleIndex]?.plate_number
              }
            />
            <p>
              Renew your third party insurance and get your vehicle license for
              free
            </p>
          </div>

          <Card>
            <div className={styles.vehiclePapers}>
              <div className={styles.papers_title}>
                <h4>{insurance}</h4>
                {/* <p>02-Jan-2023</p> */}
              </div>{" "}
              <p style={{ color: exStatusInsuranceColor }}>
                {exStatusInsurance}
              </p>
            </div>

            <div className={styles.button}>
              {!insuranceAdd && (
                <button
                  style={{
                    backgroundColor: colorState.backColor3,
                    color: colorState.color3,
                  }}
                  onClick={insuranceCartHandler}
                >
                  <div className={styles.button_inner}>
                    {!insuranceToggle && <h4>Add to cart</h4>}
                    {insuranceToggle && <h4>Added to cart</h4>}
                    <h4>
                      ₦
                      {Number(
                        paperDetails[0]?.engine[0]?.insurances?.price
                      ).toLocaleString()}
                    </h4>
                  </div>
                </button>
              )}
            </div>
          </Card>

          <Card>
            <div className={styles.vehiclePapers}>
              <div className={styles.papers_title}>
                <h4>{vehicleLicense}</h4>
                {/* <p>02-Jan-2023</p> */}
              </div>
              <p style={{ color: exStatusColor }}>{exStatus}</p>
              {/* <div className={styles.confirmStatus}> */}
              {/* <p onClick={openModalHandler}> */}
              {/* <a href="http://verify.autoreg.ng/" target="iframe_a">
                    Confirm
                  </a> */}
              {/* </p> */}
              {/* </div> */}
            </div>
            <div className={styles.button}>
              {!vlAdd && (
                <button
                  style={{
                    backgroundColor: colorState1.backColor1,
                    color: colorState1.color1,
                  }}
                  onClick={licenseCartHandler}
                >
                  <div className={styles.button_inner}>
                    {!licenseToggle && <h4>Add to cart</h4>}
                    {licenseToggle && <h4>Added to cart</h4>}
                    <h4 style={{ color: "#088457" }}>
                      FREE
                      {/* ₦
                      {Number(
                        paperDetails[0]?.engine[0]?.vls?.price
                      ).toLocaleString()} */}
                    </h4>
                  </div>
                </button>
              )}
            </div>
          </Card>

          <Card>
            <div className={styles.vehiclePapers}>
              <div className={styles.papers_title}>
                <h4>{Road}</h4>
                {/* <p>02-Jan-2023</p> */}
              </div>
              <p style={{ color: exStatusRoadColor }}>{exStatusRoad}</p>
              {/* <div className={styles.confirmStatus}> */}
              {/* <p onClick={openModalHandler}> */}
              {/* <a href="https://dvis.lg.gov.ng/verify/" target="iframe_a">
                    Confirm
                  </a> */}
              {/* </p> */}
              {/* </div> */}
            </div>
            <div className={styles.button}>
              {!roadAdd && (
                <button
                  style={{
                    backgroundColor: colorState2.backColor2,
                    color: colorState2.color2,
                  }}
                  onClick={roadCartHandler}
                >
                  <div className={styles.button_inner}>
                    {!roadToggle && <h4>Add to cart</h4>}
                    {roadToggle && <h4>Added to cart</h4>}
                    <h4>
                      ₦
                      {Number(
                        paperDetails[0]?.engine[0]?.roads.price
                      ).toLocaleString()}
                    </h4>
                  </div>
                </button>
              )}
            </div>
          </Card>
          <div className={styles.button2}>
            <Link to="/papers/cart">
              <Button disabled={!formIsValid}>Renew</Button>
            </Link>
          </div>
        </div>
      )}
      <BottomNav />
    </section>
  );
}

export default Papers;
