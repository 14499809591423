import React, { useState, useEffect } from "react";
import styles from "./ForgotPasswordForm1.module.css";
import { useNavigate } from "react-router";
import Logo from "../../Components/interface/Logo";
import Input from "../../Components/interface/Input";
import Button from "../../Components/interface/Button";
import EmailInput from "../../Components/interface/EmailInput";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/redux-store/Index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Authentication from "../../utils/api/Authentication";

function ForgotPasswordForm1() {
  let [number, setNumber] = useState("");
  let [email, setEmail] = useState("");
  let [validEmail, setValidEmail] = useState(false);
  let [numberInvalid, setNumberInvalid] = useState(false);
  let [formIsValid, setFormIsValid] = useState(false);
  let navigate = useNavigate();
  let dispatch = useDispatch();
  useEffect(() => {
    if (number.trim().length >= 11) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [number]);

  useEffect(() => {
    if (
      email.trim().length >= 1 &&
      email.includes("@") &&
      email.includes(".com")
    ) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  }, [email]);
  let submitHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await Authentication.resetPassword({
        email,
        phone_number: number,
      });
      toast.success(res.data.message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      dispatch(authActions.resetOTPToDefault());
      dispatch(
        authActions.resetPasswordDetails({
          email: email,
          phone_number: number,
        })
      );
      navigate("/forgotpassword/form2");
      return res;
    } catch (err) {
      toast.error(err.response.data.errors.message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }
  };
  let numberInputHandler = (e) => {
    setNumber(e.target.value);
  };
  let handleInputChange = (e) => {
    setEmail(e.target.value);
  };
  let numberInputBlur = () => {
    if (number.trim().length === 0) {
      setNumberInvalid(true);
    } else {
      setNumberInvalid(false);
    }
  };
  return (
    <section className={styles.login}>
      <ToastContainer />
      <div className={styles.login__hero}>
        <Logo />
        <div className={styles.header}>
          <p>Forgot Password</p>
        </div>
        <div className={styles.login__hero__description}>
          <p>We will send the recovery code to your phone number.</p>
          <p>
            Enter your Email and phone number you registered with on this
            Motormata.
          </p>
        </div>
      </div>
      <div className={styles.form}>
        <form onSubmit={submitHandler}>
          <EmailInput
            type="email"
            value={email}
            label="Email"
            onChange={handleInputChange}
            placeholder="Enter email"
            validEmail={validEmail}
            name="email"
          />
          <Input
            label="Phone Number"
            value={number}
            invalid={numberInvalid}
            placeholder="Enter phone number"
            type="tel"
            onBlur={numberInputBlur}
            onChange={numberInputHandler}
          />
          <Button disabled={!formIsValid}>Request</Button>
        </form>
      </div>
    </section>
  );
}

export default ForgotPasswordForm1;
