import React, { useState, useEffect } from "react";
import Card from "../UI/Card";
import styles from "../UI/Modal.module.css";
import Input from "./Input";
import Button from "./Button";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { authActions } from "../../store/redux-store/Index";
import { useDispatch } from "react-redux";

const initialValues = {
  name: "",
  id: "",
};
const initialBoolenValues = {
  nameInvalid: false,
  deviceIdInvalid: false,
  formIsValid: false,
};

function ScanForm(props) {
  const [values, setValues] = useState(initialValues);
  const [boolen, setBoolen] = useState(initialBoolenValues);
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  //Input state handler//
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  //Input state handler end//

  useEffect(() => {
    if (values.id.trim().length >= 1 && values.name.trim().length >= 1) {
      setBoolen({ formIsValid: true });
    } else {
      setBoolen({ formIsValid: false });
    }
  }, [values.id, values.name]);

  //Form Submission//
  let submitHandler = (e) => {
    e.preventDefault();

    let loginAuth = async () => {
      let url = `https://bpms.motormata.com/api/v1/dongle/verification/${values.id}`;
      setBoolen({ formIsValid: false });
      setLoading(true);
      let res = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      if (res.ok) {
        if (data.status === "invalid") {
          toast.error(`Invalid Device ID ❌`, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setBoolen({ formIsValid: true });
          setLoading(false);
          return;
        }
        if (data.status === "valid") {
          dispatch(authActions.plateNumber2(values.name));

          let liveData = async () => {
            let url = `https://api.dev.motormata.com/api/v1/dongle/vehicle/live-data/${data.id}`;
            setLoading(true);
            let res = await fetch(url, {
              headers: {
                "Content-Type": "application/json",
              },
            });
            let data3 = await res.json();
            if (res.ok) {
              let fetchUserData = async () => {
                let url2 = `https://bpms.motormata.com/api/v1/dongle/octamile/get/${values.name}`;
                let res = await fetch(url2, {
                  headers: {
                    "Content-Type": "application/json",
                  },
                });
                let data1 = await res.json();
                if (res.ok) {
                  if (data1 !== "NO RECORD FOUND") {
                    dispatch(authActions.resetVehicleDetailsAlt());
                    dispatch(authActions.resetVehicleData());
                    dispatch(authActions.plateNumber2(values.name));
                    dispatch(authActions.deviceID(data));
                    dispatch(authActions.detailsALt(data1[0]));
                    dispatch(
                      authActions.vin(data3.vehicle_location.vehicle_vin)
                    );
                    dispatch(authActions.loggedIn(data));
                    navigate("/scanner");
                    props.setUserLogged(true);
                    props.setDiagForm(false);
                  } else if (data1 === "NO RECORD FOUND") {
                    let plateNumberHandler = async () => {
                      let url1 = `https://a6111.imas.octamile.com:62004/`;
                      setBoolen({ formIsValid: false });
                      setLoading(true);
                      dispatch(authActions.resetToDefault2());
                      let res = await fetch(url1, {
                        method: "POST",
                        body: JSON.stringify({
                          srvcVrsn: "2",
                          userInfo: {
                            id: process.env.REACT_APP_USER_ID,
                            athrzt: {
                              id: process.env.REACT_APP_AUTH_ID,
                              key: process.env.REACT_APP_KEY2,
                            },
                          },
                          cmmnd: {
                            cmmnd: `dump vins_regId:${values.name.toLowerCase()}*I_PRFL`,
                          },
                        }),
                        headers: {
                          "Content-Type": "application/json",
                        },
                      });
                      let data2 = await res.json();
                      console.log("octamile" + data2);
                      setBoolen({ formIsValid: true });
                      setLoading(false);
                      if (res.ok) {
                        let postReq = async () => {
                          let url2 = `https://bpms.motormata.com/api/v1/dongle/octamile/post`;
                          let response = await fetch(url2, {
                            method: "POST",
                            body: JSON.stringify({
                              plate_no: values.name,
                              chsssId: data2.chsssId,
                              cmmncmDate: data2.insrncDetail[0].cmmncmDate,
                              expiryDate: data2.insrncDetail[0].expiryDate,
                              type: data2.insrncDetail[0].type,
                              insrnStatus: data2.insrncStatus,
                              year: "",
                              make: "",
                              model: "",
                            }),
                            headers: {
                              "Content-Type": "application/json",
                            },
                          });
                          const OctaData = await response.json();
                          if (response.ok) {
                            console.log(OctaData);
                          } else {
                            console.log(OctaData);
                          }
                        };
                        postReq();
                        if (data3.vehicle_location.vehicle_vin === "") {
                          dispatch(authActions.plateNumber2(values.name));
                          dispatch(authActions.deviceID(data));
                          navigate("/registervehicle");
                        } else {
                          dispatch(authActions.plateNumber2(values.name));
                          dispatch(authActions.deviceID(data));
                          dispatch(authActions.loggedIn(data));
                          console.log(data);
                          navigate("/scanner");
                          props.setUserLogged(true);
                          props.setDiagForm(false);
                        }
                      } else {
                        console.log(data2);
                      }
                    };
                    plateNumberHandler();
                  }
                } else {
                  console.log(data1);
                }
              };
              fetchUserData();
            } else {
              console.log(data3);
            }
          };
          liveData();
        }
      } else {
        console.log(data);
      }
    };
    loginAuth();
  };

  const closeHandler = () => {
    props.setDiagForm(false);
    // navigate("/home");
  };
  //Form Submission end//

  let nameInputBlur = () => {
    if (values.name.trim().length === 0) {
      setBoolen({ ...boolen, nameInvalid: true });
    } else {
      setBoolen({ ...boolen, nameInvalid: false });
    }
  };
  let IdInputBlur = () => {
    if (values.id.trim().length === 0) {
      setBoolen({ ...boolen, deviceIdInvalid: true });
    } else {
      setBoolen({ ...boolen, deviceIdInvalid: false });
    }
  };
  return (
    <div>
      <div className={styles.backdrop} onClick={closeHandler}>
        <Card
          className={styles.modal}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <ToastContainer />
          <header>
            <h2 className={styles.header}>Diagnose your vehicle?</h2>
          </header>
          <div className={styles.content}>
            <p>
              Enter your Device ID and plate number to diagnose your vehicle
            </p>
          </div>
          <form onSubmit={submitHandler} className={styles.form}>
            <Input
              label="Device ID."
              onBlur={IdInputBlur}
              invalid={boolen.deviceIdInvalid}
              onChange={handleInputChange}
              value={values.id}
              type="name"
              placeholder="Enter recipient's device ID"
              name="id"
            />

            <Input
              label="Plate Number"
              onBlur={nameInputBlur}
              invalid={boolen.nameInvalid}
              onChange={handleInputChange}
              value={values.name}
              type="name"
              placeholder="Enter your plate number"
              name="name"
            />
            {!loading && <Button disabled={!boolen.formIsValid}>Save</Button>}
            {loading && (
              <Button disabled={!boolen.formIsValid}>Loading...</Button>
            )}
          </form>
          <div className={styles.login__options}>
            <div className={styles.login__options_inner}>
              <p>Don’t have Device ID.?</p>
              <Link to="/services">Visit smartscan centre</Link>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default ScanForm;
