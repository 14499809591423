import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./Insurance.module.css";
import placeholder from "../../asset/images/imgPlaceholder.png";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../store/redux-store/Index";
import Button from "../../Components/interface/Button";
import Webcam from "react-webcam";
import Input from "../../Components/interface/Input";
import Insurance from "../../utils/api/Insurance";

function InsuranceRegister() {
  // const [pic, setPic] = useState(null);
  const [maxUpload, setMaxUpload] = useState(false);
  let [number, setNumber] = useState("");
  let [validNumber, setValidNumber] = useState(false);
  let [numberInvalid, setNumberInvalid] = useState(false);
  let [formIsValid, setFormIsValid] = useState(false);
  const webcamRef = useRef(null);

  const videoConstraints = {
    width: { min: 480 },
    height: { min: 720 },
    aspectRatio: 0.6666666667,
    facingMode: { exact: "environment" },
  };

  const uploadedImages = useSelector(
    (state) => state.auth.uploadedImagesStorage
  );

  const dispatch = useDispatch();

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    dispatch(authActions.uploadCarInsurancePicture(imageSrc));
  }, [webcamRef, dispatch]);
  // const pictureChangeHandler = (e) => {
  //   let picture = e.target.files[0];
  //   setPic(picture);
  // };

  useEffect(() => {
    if (number.trim().length >= 3 && uploadedImages.length === 4) {
      setValidNumber(true);
      setFormIsValid(true);
    } else {
      setValidNumber(false);
      setFormIsValid(false);
    }
  }, [number, uploadedImages.length]);

  useEffect(() => {
    if (uploadedImages.length >= 4) {
      setMaxUpload(true);
    } else {
      setMaxUpload(false);
    }
  }, [uploadedImages.length]);

  const formSubmitHandler = async () => {
    try {
      setFormIsValid(false);
      const res = await Insurance.claimRegistration({
        plate_no: number,
        pic1: uploadedImages[0],
        pic2: uploadedImages[1],
        pic3: uploadedImages[2],
        pic4: uploadedImages[3],
      });
      console.log(res.data);
      setFormIsValid(true);
    } catch (error) {
      setFormIsValid(true);
      console.log(error.response.data.errors);
    }
  };

  // //   Function to get url of the picture using file reader api //
  // useEffect(() => {
  //   let fileReader,
  //     isCancel = false;
  //   if (pic) {
  //     fileReader = new FileReader();
  //     fileReader.onload = (e) => {
  //       const { result } = e.target;
  //       if (!isCancel) {
  //         dispatch(authActions.uploadCarInsurancePicture(result));
  //       }
  //     };
  //     fileReader.readAsDataURL(pic);
  //   }
  //   return () => {
  //     isCancel = true;
  //     if (fileReader && fileReader.readyState === 1) {
  //       fileReader.abort();
  //     }
  //   };
  // }, [pic, dispatch]);

  //   Function to get url of the video using file reader api //
  //   useEffect(() => {
  //     let fileReader,
  //       isCancel = false;
  //     if (video) {
  //       fileReader = new FileReader();
  //       fileReader.onload = (e) => {
  //         const { result } = e.target;
  //         if (result && !isCancel) {
  //           setFileVideoDataURL(result);
  //         }
  //       };
  //       fileReader.readAsDataURL(video);
  //     }
  //     return () => {
  //       isCancel = true;
  //       if (fileReader && fileReader.readyState === 1) {
  //         fileReader.abort();
  //       }
  //     };
  //   }, [video]);
  const clearPicturesHandler = () => {
    dispatch(authActions.clearUploadCarInsurancePicture());
  };
  const numberInputHandler = (e) => {
    setNumber(e.target.value);
  };
  const numberInputBlur = () => {
    if (number.trim().length === 0) {
      setNumberInvalid(true);
    } else {
      setNumberInvalid(false);
    }
  };
  const image = uploadedImages.length !== 0 ? uploadedImages[0] : placeholder;
  const image2 = uploadedImages.length !== 0 ? uploadedImages[1] : placeholder;
  const image3 = uploadedImages.length !== 0 ? uploadedImages[2] : placeholder;
  const image4 = uploadedImages.length !== 0 ? uploadedImages[3] : placeholder;
  return (
    <div>
      <div className={styles.register_header}>
        <h4>Register your vehicle</h4>
        <p>Input further car details to be able to make claim</p>
      </div>
      {!maxUpload && (
        <div className={styles.imageUploadWrapper}>
          <Webcam
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            mirrored={false}
            videoConstraints={videoConstraints}
            height={300}
            width={280}
          />
          {/* <label>
          <svg
            width="21"
            height="17"
            viewBox="0 0 21 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.5605 1.5H20.25L21 2.25V15.75L20.25 16.5H0.75L0 15.75V2.25L0.75 1.5H6.4395L7.719 0.219L8.25 0H12.75L13.281 0.219L14.5605 1.5ZM1.5 15H19.5V3H14.25L13.719 2.781L12.4395 1.5H8.5605L7.281 2.781L6.75 3H1.5V15ZM3.75 4.5C3.55109 4.5 3.36032 4.57902 3.21967 4.71967C3.07902 4.86032 3 5.05109 3 5.25C3 5.44891 3.07902 5.63968 3.21967 5.78033C3.36032 5.92098 3.55109 6 3.75 6C3.94891 6 4.13968 5.92098 4.28033 5.78033C4.42098 5.63968 4.5 5.44891 4.5 5.25C4.5 5.05109 4.42098 4.86032 4.28033 4.71967C4.13968 4.57902 3.94891 4.5 3.75 4.5ZM10.5 6C11.2956 6 12.0587 6.31607 12.6213 6.87868C13.1839 7.44129 13.5 8.20435 13.5 9C13.5 9.79565 13.1839 10.5587 12.6213 11.1213C12.0587 11.6839 11.2956 12 10.5 12C9.70435 12 8.94129 11.6839 8.37868 11.1213C7.81607 10.5587 7.5 9.79565 7.5 9C7.5 8.20435 7.81607 7.44129 8.37868 6.87868C8.94129 6.31607 9.70435 6 10.5 6ZM10.5 4.5C9.30653 4.5 8.16193 4.97411 7.31802 5.81802C6.47411 6.66193 6 7.80653 6 9C6 10.1935 6.47411 11.3381 7.31802 12.182C8.16193 13.0259 9.30653 13.5 10.5 13.5C11.6935 13.5 12.8381 13.0259 13.682 12.182C14.5259 11.3381 15 10.1935 15 9C15 7.80653 14.5259 6.66193 13.682 5.81802C12.8381 4.97411 11.6935 4.5 10.5 4.5Z"
              fill="#008069"
            />
          </svg>
          {!maxUpload && (
            <input
              type="file"
              name="picture"
              accept="image/*"
              onChange={pictureChangeHandler}
              capture="environment"
              className={styles.input}
            ></input>
          )}
          <p>Take a picture</p>
        </label> */}
          <Button onClick={capture}>Capture</Button>
        </div>
      )}
      <div className={styles.videoUpload}>
        <label onClick={clearPicturesHandler}>
          <p>Clear Pictures</p>
          <svg
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.677 3.888C20.1573 1.368 16.8457 0 13.2822 0C9.75469 0 6.40714 1.368 3.88748 3.888C-1.29583 9.072 -1.29583 17.496 3.88748 22.644C6.40714 25.164 9.71869 26.532 13.2822 26.532C16.8097 26.532 20.1573 25.164 22.677 22.644C27.8603 17.496 27.8603 9.072 22.677 3.888ZM22.353 22.356C19.9413 24.768 16.7018 26.1 13.2822 26.1C9.86267 26.1 6.62311 24.768 4.21143 22.356C-0.791893 17.352 -0.791893 9.216 4.21143 4.212C6.62311 1.8 9.86267 0.468 13.2822 0.468C16.7018 0.468 19.9413 1.8 22.353 4.212C27.3563 9.216 27.3563 17.352 22.353 22.356ZM17.1337 9.756L13.4982 13.392L17.1337 17.028C17.2057 17.1 17.2057 17.244 17.1337 17.352C17.0977 17.388 17.0257 17.424 16.9897 17.424C16.9177 17.424 16.8817 17.388 16.8457 17.352L13.1742 13.716L9.53872 17.352C9.50272 17.388 9.43073 17.424 9.39474 17.424C9.32275 17.424 9.28675 17.388 9.25076 17.352C9.17877 17.28 9.17877 17.136 9.25076 17.028L12.8863 13.392L9.21476 9.756C9.14277 9.684 9.14277 9.54 9.21476 9.432C9.28675 9.36 9.43073 9.36 9.53872 9.432L13.1742 13.068L16.8097 9.432C16.8817 9.36 17.0257 9.36 17.1337 9.432C17.2057 9.54 17.2057 9.684 17.1337 9.756Z"
              fill="#008069"
            />
          </svg>

          {/* <input
            type="file"
            id="videoFile"
            accept="video/*"
            onChange={videoChangeHandler}
            capture="environment"
            className={styles.input}
          ></input> */}
        </label>
      </div>
      <div className={styles.uploadedImageWrapper}>
        <div className={styles.uploadedImageWrapper_container}>
          <div className={styles.uploadedImageWrapper_inner}>
            <img src={image} alt="" />
          </div>
        </div>

        <div className={styles.uploadedImageWrapper_container}>
          <div className={styles.uploadedImageWrapper_inner}>
            <img src={image2} alt="" />
          </div>
        </div>

        <div className={styles.uploadedImageWrapper_container}>
          <div className={styles.uploadedImageWrapper_inner}>
            <img src={image3} alt="" />
          </div>
        </div>

        <div className={styles.uploadedImageWrapper_container}>
          <div className={styles.uploadedImageWrapper_inner}>
            <img src={image4} alt="" />
          </div>
        </div>
      </div>
      <div className={styles.plateNumber_input}>
        <Input
          validEmail={validNumber}
          label="Plate Number"
          value={number}
          emailInvalid={numberInvalid}
          placeholder="Enter plate Number"
          type="text"
          onBlur={numberInputBlur}
          onChange={numberInputHandler}
        />
      </div>
      <div>
        <Button onClick={formSubmitHandler} disabled={!formIsValid}>
          Proceed
        </Button>
      </div>
      {/* <video src={fileVideoDataURL} controls></video> */}
    </div>
  );
}

export default InsuranceRegister;
