import React, { useEffect, useState } from "react";
import styles from "./Modal2.module.css";
import Card from "../UI/Card";
import Button from "../interface/Button";
import WhiteButton from "../interface/WhiteButton";

let Modal2 = (props) => {
  const [emptyField, setEmptyField] = useState(false);
  useEffect(() => {
    if (props.accept === "" || props.back === "") {
      setEmptyField(true);
    } else {
      setEmptyField(false);
    }
  }, [props.accept, props.back]);
  return (
    <div className={styles.backdrop} onClick={props.close}>
      <Card
        className={styles.modal2}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {props.children}
        <header>
          <h2 className={styles.header}>{props.title}</h2>
        </header>
        <div className={styles.content}>
          <p>{props.message}</p>
        </div>
        {!emptyField && (
          <footer className={styles.actions}>
            <div>
              <WhiteButton type="submit" onClick={props.confirm}>
                {props.accept}
              </WhiteButton>
            </div>
            <div>
              <Button type="submit" onClick={props.close}>
                {props.back}
              </Button>
            </div>
          </footer>
        )}
      </Card>
    </div>
  );
};

export default Modal2;
