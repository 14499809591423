import React, { useState, useEffect } from "react";
import styles from "../../auth/SignUp.module.css";
import Button from "../../../Components/interface//Button";
import Input from "../../../Components/interface//Input";
import Logo from "../../../Components/interface/Logo";
import { useNavigate } from "react-router";

const initialValues = {
  name: "",
  id: "",
  number: "",
  address: "",
};
const initialBoolenValues = {
  nameInvalid: false,
  addressInvalid: false,
  deviceIdInvalid: false,
  phoneNumberInvalid: false,
  formIsValid: false,
};

function AddRecipient() {
  const [values, setValues] = useState(initialValues);
  const [boolen, setBoolen] = useState(initialBoolenValues);

  //Input state handler//
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  //Input state handler end//
  let navigate = useNavigate();

  useEffect(() => {
    if (
      values.id.trim().length >= 1 &&
      values.name.trim().length >= 1 &&
      values.number.trim().length >= 11 &&
      values.address.trim().length >= 1
    ) {
      setBoolen({ formIsValid: true });
    } else {
      setBoolen({ formIsValid: false });
    }
  }, [values.id, values.number, values.name, values.address]);

  //Form Submission//
  let submitHandler = (e) => {
    e.preventDefault();
    console.log(values);
    navigate("/services");
  };
  //Form Submission end//

  let nameInputBlur = () => {
    if (values.name.trim().length === 0) {
      setBoolen({ ...boolen, nameInvalid: true });
    } else {
      setBoolen({ ...boolen, nameInvalid: false });
    }
  };
  let IdInputBlur = () => {
    if (values.id.trim().length === 0) {
      setBoolen({ ...boolen, deviceIdInvalid: true });
    } else {
      setBoolen({ ...boolen, deviceIdInvalid: false });
    }
  };
  let pNumberInputBlur = () => {
    if (values.number.trim().length === 0) {
      setBoolen({ ...boolen, phoneNumberInvalid: true });
    } else {
      setBoolen({ ...boolen, phoneNumberInvalid: false });
    }
  };

  let addressInputBlur = () => {
    if (values.address.trim().length === 0) {
      setBoolen({ ...boolen, addressInvalid: true });
    } else {
      setBoolen({ ...boolen, addressInvalid: false });
    }
  };

  return (
    <section className={styles.login}>
      <div className={styles.login__hero}>
        <Logo />
        <div className={styles.header}>
          <p>Add Recipient</p>
        </div>
      </div>
      <div className={styles.form}>
        <form onSubmit={submitHandler}>
          <Input
            label="Name / Workshop"
            onBlur={nameInputBlur}
            invalid={boolen.nameInvalid}
            onChange={handleInputChange}
            value={values.name}
            type="name"
            placeholder="Enter recipient's name"
            name="name"
          />

          <Input
            label="Device ID."
            onBlur={IdInputBlur}
            invalid={boolen.deviceIdInvalid}
            onChange={handleInputChange}
            value={values.id}
            type="name"
            placeholder="Enter recipient's device ID"
            name="id"
          />

          <Input
            label="Phone Number"
            onBlur={pNumberInputBlur}
            invalid={boolen.phoneNumberInvalid}
            onChange={handleInputChange}
            value={values.number}
            type="number"
            placeholder="Enter recipient's phone number"
            name="number"
          />

          <Input
            label="Address / Location"
            onBlur={addressInputBlur}
            invalid={boolen.addressInvalid}
            onChange={handleInputChange}
            value={values.address}
            type="name"
            placeholder="Enter recipient's address or location"
            name="address"
          />
          <Button disabled={!boolen.formIsValid}>Save</Button>
        </form>
      </div>
    </section>
  );
}

export default AddRecipient;
