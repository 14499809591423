import React, { useState } from "react";
import BottomNav from "../../../Components/BottomNav";
import SearchInput2 from "../../../Components/inputs/SearchInput2";
import NavBar from "../../../Components/interface/NavBar";
import InsuranceMenu from "../../Insurance/InsuranceMenu";
import styles from "./Services.module.css";
import WorkShopList from "./WorkShopList";

function Services(props) {
  const [searchInput, setSearchInput] = useState("");
  const [result, setResult] = useState(false);
  const searchInputChangeHandler = (e) => {
    setSearchInput(e.target.value);
  };
  const formSubmitHandler = (e) => {
    e.preventDefault();
    console.log(searchInput);
    setResult(true);
    setSearchInput("");
  };
  return (
    <section>
      <NavBar title="Maintenance" setShowMenu={props.setShowMenu} />
      <div className={styles.head}>
        <div className={styles.main_hero}>
          <InsuranceMenu
            first="Services"
            first_link="services"
            second="Spare Parts"
            second_link="spare-parts"
          />
          <form onSubmit={formSubmitHandler}>
            <div>
              <SearchInput2
                value={searchInput}
                onChange={searchInputChangeHandler}
                name="search"
                placeholder="Search"
              />
            </div>
          </form>
          <WorkShopList
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            result={result}
          />
        </div>
      </div>

      <BottomNav />
    </section>
  );
}

export default Services;
