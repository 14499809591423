import React from "react";
import styles from "./Calender.module.css";
import DatePicker from "react-datepicker";
import "../../Pages/account/Wallet.css";
import "react-datepicker/dist/react-datepicker.css";

function Calender(props) {
  return (
    <div className={styles.input_wrapper}>
      <DatePicker
        placeholderText={props.placeholderText}
        selected={props.selected}
        onChange={props.onChange}
        // openToDate={props.openToDate}
        className={styles.input}
        dateFormat="dd/MM/yyyy" // set date format if needed
      />
    </div>
  );
}

export default Calender;
