import React from "react";
import { Outlet } from "react-router";
import BottomNav from "../../Components/BottomNav";
import NavBar from "../../Components/interface/NavBar";
import styles from "./Insurance.module.css";
import InsuranceMenu from "./InsuranceMenu";

function Insurance(props) {
  return (
    <section>
      <NavBar title="Insurance" setShowMenu={props.setShowMenu} />
      <div className={styles.head}>
        <div className={styles.main_hero}>
          <InsuranceMenu
            first="Insurance"
            first_link="insurance/register"
            second="Insurance Claim"
            second_link="insurance/claims"
          />
          <Outlet />
        </div>
      </div>
      <BottomNav />
    </section>
  );
}

export default Insurance;
