import React from "react";
import styles from "./MyCar.module.css";
import NavBar from "../../../Components/interface/NavBar";
import BottomNav from "../../../Components/BottomNav";
import { Outlet } from "react-router";
import GoogleMapReact from "google-map-react";
import { NavLink } from "react-router-dom";

// const AnyReactComponent = (props) => <div style={{ 'height': '30px' }}><img src={props.marker} alt='My car' /></div>;

function MyCar(props) {
  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
      position: { lat: 6.5158, lng: 3.3898 },
      map,
      title: "Hello World!",
    });
    return marker;
  };

  let closeMenuHandler = () => {
    props.setShowMenu(false);
  };

  const defaultProps = {
    center: {
      lat: 6.5158,
      lng: 3.3898,
    },
    zoom: 11,
  };
  return (
    <section className={styles.myCar}>
      <NavBar title="My Car" setShowMenu={props.setShowMenu} />
      <div className={styles.myCar__body} onClick={closeMenuHandler}>
        <div className={styles.map__wrapper}>
          <div style={{ height: "35vh", width: "100%" }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.REACT_APP_KEY }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
            >
              {/* <AnyReactComponent
                                lat={6.4923659}
                                lng={3.3892904}
                                marker={map}
                            /> */}
            </GoogleMapReact>
          </div>
        </div>
        <div className={styles.myCar__body__dashboard}>
          <div className={styles.myCar__body__dashboard__menu}>
            <div>
              <NavLink
                to="/mycar/livemetrics"
                className={(navData) =>
                  navData.isActive ? `${styles.active}` : ""
                }
              >
                <p>Live Metrics</p>
              </NavLink>
              <NavLink
                to="/mycar/driving"
                className={(navData) =>
                  navData.isActive ? `${styles.active}` : ""
                }
              >
                <p>Driving</p>
              </NavLink>
              <NavLink
                to="/mycar/analysis"
                className={(navData) =>
                  navData.isActive ? `${styles.active}` : ""
                }
              >
                <p>Diagnosis</p>
              </NavLink>
              <NavLink
                to="/mycar/maintenance"
                className={(navData) =>
                  navData.isActive ? `${styles.active}` : ""
                }
              >
                <p>Maintenance</p>
              </NavLink>
            </div>
          </div>
          <div className={styles.outlet}>
            <Outlet />
          </div>
        </div>
      </div>
      <BottomNav />
    </section>
  );
}

export default MyCar;
