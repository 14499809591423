import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Images from "../../utils/CarImages";
import { useParams } from "react-router";
import LiveMetricsReport2 from "./LiveMetrics2";
import styles from "./Report.module.css";
import NavBar from "../interface/NavBar";
import LoadingAnimation from "../UI/LoadingAnimation";
import image from "../../asset/images/car.png";
import VehicleInformation2 from "../UI/VehicleInformation2";
import CustomerScans from "../../utils/api/CustomerScans";
import VehicleFaultCodes from "../vehicle/VehicleFaultCodes";
import Contact from "../interface/Contact";
import BottomNav from "../BottomNav";

function ReportPage2(props) {
  const [reportData, setReportData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();

  const personalData = useSelector((data) => data.auth);

  useEffect(() => {
    const fetchScanData = async () => {
      setIsLoading(true);
      try {
        const res = await CustomerScans.getVendorReportsDetails({
          report_id: id,
          access_token: personalData.token,
        });
        setReportData(res.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.log(err.response.data);
      }
    };
    fetchScanData();
  }, [id, personalData.token]);

  const carImage = Images?.find(
    (data) =>
      data?.name?.toUpperCase() ===
      `${
        reportData?.scan_data?.vehicle_data?.vehicle_year
      }-${reportData?.scan_data?.vehicle_data?.vehicle_make?.toUpperCase()}-${reportData?.scan_data?.vehicle_data?.vehicle_model?.toUpperCase()}`
  );

  return (
    <div>
      <NavBar title="Report" setShowMenu={props.setShowMenu} />
      {isLoading && (
        <div className={styles.loading}>
          <LoadingAnimation />
        </div>
      )}
      {!isLoading && (
        <div className={styles.head}>
          <div className={styles.buyDongle__wrapper}>
            <img src={carImage?.image || image} alt="car" />
          </div>
          <div>
            <VehicleInformation2
              data3={reportData}
              vehicle_make={reportData?.scan_data?.vehicle_data?.vehicle_make}
              vehicle_model={reportData?.scan_data?.vehicle_data?.vehicle_model}
              vehicle_year={reportData?.scan_data?.vehicle_data?.vehicle_year}
              vin={reportData?.scan_data?.vehicle_vin}
              plate_number={reportData?.scan_data?.vehicle_data?.plate_number}
            />
          </div>
          <div className={styles.vehicleInfo}>
            <div>
              <LiveMetricsReport2 metrics={reportData} color="#008069" />
            </div>
            <div className={styles.system_codes}>
              <div className={styles.system_codes_head}>
                <h3>System Fault Code</h3>
                <p>
                  Here are the error codes diagnosed as at the time of last
                  test.
                </p>
              </div>
            </div>
            {reportData?.scan_data.vehicle_errors.map((data, index) => (
              <VehicleFaultCodes
                key={index}
                title={data.code || data}
                liveMetrics={reportData}
                description={data.description || null}
              />
            ))}
          </div>
          <div>
            <Contact id={id} data3={reportData} />
          </div>
        </div>
      )}
      <BottomNav />
    </div>
  );
}

export default ReportPage2;
