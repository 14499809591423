import React, { useEffect, useState } from "react";
import BottomNav from "../../Components/BottomNav";
import NavBar from "../../Components/interface/NavBar";
import styles from "./Insurance.module.css";
import image4 from "../../asset/images/car.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import Button from "../../Components/interface/Button";
import { useSelector } from "react-redux";
import Images from "../../utils/CarImages";
import VehicleDetails2 from "../../Components/interface/VehicleDetails2";

function InsuranceHome(props) {
  const [greeting, setGreeting] = useState("");

  const vehicleIndex = useSelector((data) => data.dongles.currentVehicle);

  let personalData = useSelector((data) => data.auth.generalLoginData);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "linear",
    centerMode: true,
  };

  let carImage = Images?.find(
    (data, index) =>
      data.name?.toUpperCase() ===
      `${
        personalData[0]?.vehicle_data[vehicleIndex]?.vehicle_year
      }-${personalData[0]?.vehicle_data[
        vehicleIndex
      ]?.vehicle_make?.toUpperCase()}-${personalData[0]?.vehicle_data[
        vehicleIndex
      ]?.vehicle_model?.toUpperCase()}`
  );

  useEffect(() => {
    const now = new Date();
    const hour = now.getHours();

    if (hour >= 5 && hour < 12) {
      setGreeting("Good morning ☀️");
    } else if (hour >= 12 && hour < 18) {
      setGreeting("Good afternoon 🌤️");
    } else {
      setGreeting("Good night 🌙");
    }
  }, []);

  return (
    <section>
      <NavBar title="Insurance" setShowMenu={props.setShowMenu} />
      <div className={styles.head}>
        <div className={styles.main_hero}>
          <p>
            {greeting}, {personalData[0].fullname}!
          </p>
          <div className={styles.carType}>
            <div className={styles.buyDongle__wrapper}>
              <div></div>
              <img src={carImage?.image || image4} alt="dongle" />
            </div>
          </div>
        </div>
        <div className={styles.details}>
          <VehicleDetails2
            vehicle_make={
              personalData[0]?.vehicle_data[vehicleIndex]?.vehicle_make
            }
            vehicle_model={
              personalData[0]?.vehicle_data[vehicleIndex]?.vehicle_model
            }
            plate_number={
              personalData[0]?.vehicle_data[vehicleIndex]?.plate_number
            }
          />
        </div>

        <div className={styles.footer}>
          <div className={styles.footer_inner}>
            <h3>Insurance Claim</h3>
            <p>Lorem jsfruhije qvyfey askuri nyj fefj uytreu. .</p>
          </div>
          <div className={styles.footer_button}>
            <Link to="/insurance">
              <Button>Claim</Button>
            </Link>
          </div>
        </div>
        <div className={styles.insurance_packages}>
          <div className={styles.insurance_packages_header}>
            <p>Insurance packages</p>
          </div>
          <div className={styles.insurance_packages_inner}>
            <Slider {...settings}>
              <div className={styles.carousel}>
                <div className={styles.carousel_inner}>
                  <div
                    className={styles.carousel_inner_header}
                    style={{
                      backgroundColor: "#FFF5CC",
                      color: "#FD4D1E",
                    }}
                  >
                    {/* <div> */}
                    <h4>Comprehensive Insurance</h4>
                    <h2>₦3,999</h2>
                    <p>Per week</p>
                  </div>
                  {/* </div> */}
                  <div className={styles.additionalInfo}>
                    <p>* Affordable Charges</p>
                    <p>* Charged Once in a Month</p>
                    <p>* Financial Protection</p>
                    <p>* Protects Auto Lenders</p>

                    <div className={styles.cardButton}>
                      <button
                        style={{
                          backgroundColor: "white",
                          color: "#FD4D1E",
                          border: "1px solid #FD4D1E",
                        }}
                        disabled
                      >
                        Coming soon
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.carousel}>
                <div className={styles.carousel_inner}>
                  <div
                    className={styles.carousel_inner_header}
                    style={{ backgroundColor: "#24B7A4", color: "#FFFFFF" }}
                  >
                    <h4>Comprehensive Insurance</h4>
                    <h2>₦3,999</h2>
                    <p>Per week</p>
                  </div>
                  <div className={styles.additionalInfo}>
                    <p>* Affordable Charges</p>
                    <p>* Charged Once in a Month</p>
                    <p>* Financial Protection</p>
                    <p>* Protects Auto Lenders</p>

                    <div className={styles.cardButton}>
                      <button disabled style={{ backgroundColor: "#24B7A4" }}>
                        Coming soon
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.carousel}>
                <div className={styles.carousel_inner}>
                  <div
                    className={styles.carousel_inner_header}
                    style={{ backgroundColor: "#A5A6F633", color: "#5D5FEF" }}
                  >
                    <h4>Comprehensive Insurance</h4>
                    <h2>₦3,999</h2>
                    <p>Per week</p>
                  </div>
                  <div className={styles.additionalInfo}>
                    <p>* Affordable Charges</p>
                    <p>* Charged Once in a Month</p>
                    <p>* Financial Protection</p>
                    <p>* Protects Auto Lenders</p>
                    <div className={styles.cardButton}>
                      <button
                        disabled
                        style={{
                          backgroundColor: "white",
                          color: "#5D5FEF",
                          border: "1px solid #5D5FEF",
                        }}
                      >
                        Coming soon
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <BottomNav />
    </section>
  );
}

export default InsuranceHome;
