import React, { useCallback, useEffect, useState } from "react";
import styles from "./Health.module.css";
import NavBar from "../../Components/interface/NavBar";
import BottomNav from "../../Components/BottomNav";
import VehicleInfo2 from "../../Components/vehicle/VehicleInfo2";
import GeneralLiveMetrics from "../../Components/interface/GeneralLiveMetrics";
import { useDispatch, useSelector } from "react-redux";
import CustomerScans from "../../utils/api/CustomerScans";
import SmallCard from "../../Components/interface/SmallCard";
import Button from "../../Components/interface/Button";
import VehicleFaultCodes from "../../Components/vehicle/VehicleFaultCodes";
import { dongleActions } from "../../store/redux-store/dongle-store";
import { useNavigate } from "react-router-dom";
import Modal from "../../Components/UI/Modal";
import RoutineMaintenance from "../../Components/vehicle/RoutineMaintenance";

function Health(props) {
  const [loader, setLoader] = useState(false);
  const personalData = useSelector((data) => data.auth);

  const dongle = useSelector((data) => data.dongles);

  const liveMetrics = dongle.liveMetrics[0];

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const fetchBasicMetrics = useCallback(async () => {
    const res = await CustomerScans.scanVehiclesHandler({
      user: personalData.generalLoginData[0].id,
      device: dongle?.dongleData[0]?.dongle,
      access_token: personalData.token,
    });
    dispatch(dongleActions.getLiveMetrics(res.data.data));
  }, [
    dispatch,
    personalData.generalLoginData,
    personalData.token,
    dongle?.dongleData,
  ]);

  useEffect(() => {
    if (dongle?.dongleData[0]?.dongle?.length !== 0) {
      //fetch basic metrics
      fetchBasicMetrics();
    }
  }, [fetchBasicMetrics, dongle?.dongleData]);

  useEffect(() => {
    let intervalId;
    if (liveMetrics?.scan_data?.vehicle_live_data?.online === true) {
      fetchBasicMetrics(); // Initial fetch
      intervalId = setInterval(() => {
        fetchBasicMetrics(); // Fetch data every 30 seconds
      }, 30000); // 30 seconds in milliseconds
    }

    return () => {
      clearInterval(intervalId); // Clear interval when component unmounts
    };
  }, [liveMetrics?.scan_data?.vehicle_live_data?.online, fetchBasicMetrics]);

  // Button to trigger scan of vehicle
  const scanHandler = () => {
    setLoader(true);
    setTimeout(() => {
      navigate("/health/report-page");
      setLoader(false);
    }, 3000);
  };

  const navigateToLastScanHandler = () => {
    navigate("/history/diagnosis");
  };

  return (
    <section>
      <NavBar
        onlineStatus={liveMetrics?.scan_data?.vehicle_live_data?.online}
        title="Health"
        setShowMenu={props.setShowMenu}
      />
      {loader && (
        <Modal message="Getting report ready" back="" accept="" loader="true" />
      )}
      <div className={styles.head}>
        <VehicleInfo2
          token={personalData.token}
          liveMetrics={liveMetrics}
          data={personalData.generalLoginData}
        />
        {personalData?.generalLoginData[0]?.dongles?.length !== 0 ? (
          <div>
            <SmallCard className={styles.buyDongle__order2}>
              <div className={styles.buyDongle__order__text}>
                <h3>Car Health</h3>
                <p>Monitor the health of your car now.</p>
                {!liveMetrics?.scan_data?.vehicle_live_data?.online && (
                  <p>*Device has to be online to monitor</p>
                )}
              </div>

              <div className={styles.buyDongle__order__button}>
                <Button
                  onClick={scanHandler}
                  disabled={!liveMetrics?.scan_data?.vehicle_live_data?.online}
                >
                  Monitor
                </Button>
              </div>
            </SmallCard>
            <GeneralLiveMetrics metrics={liveMetrics} />

            <div>
              {liveMetrics?.scan_data?.vehicle_errors?.length >= 1 && (
                <div className={styles.fault_code}>
                  <h1>Fault code</h1>
                  <p>
                    {liveMetrics?.scan_data?.vehicle_errors?.length} error
                    code(s)
                  </p>
                </div>
              )}
              <div className={styles.error_wrapper}>
                {liveMetrics?.scan_data?.vehicle_errors?.map((data, index) => (
                  <VehicleFaultCodes
                    title={data.code}
                    liveMetrics={liveMetrics}
                    description={data.description}
                  />
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <SmallCard className={styles.car_health_card}>
              <div className={styles.car_health_card_text}>
                <h3>Car Health</h3>
                <p>
                  Click the button below to view your vehicle’s previous scan
                  results{" "}
                </p>
              </div>
              <div className={styles.car_health_card_btn}>
                <Button onClick={navigateToLastScanHandler}>
                  View Last Seen
                </Button>
              </div>
            </SmallCard>
            <RoutineMaintenance />
          </div>
        )}
      </div>
      <BottomNav />
    </section>
  );
}

export default Health;
