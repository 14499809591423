import React, { useCallback, useEffect, useState } from "react";
import styles from "./Papers.module.css";
import NavBar from "../../../Components/interface/NavBar";
import BottomNav from "../../../Components/BottomNav";
import Button from "../../../Components/interface/Button";
import { useNavigate } from "react-router";
import Input from "../../../Components/interface/Input";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../Components/UI/Modal";
import Modal2 from "../../../Components/UI/Modal";
import { authActions } from "../../../store/redux-store/Index";
import CarPapersAPi from "../../../utils/api/CarPapersApi";

function Invoice(props) {
  const [OTP, setOTP] = useState("");
  const [discount, setDiscount] = useState("");
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [errMsgModal, setErrMsgModal] = useState(false);
  const [bankDetails, setBankDetails] = useState(false);
  const [generatedAccountData, setGeneratedAccountData] = useState([]);
  const [checkoutData, setCheckoutData] = useState([]);

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const personalData = useSelector((data) => data.auth.generalLoginData);
  const magento = useSelector((data) => data.auth.magentoProdId);
  const deliveryData = useSelector((data) => data.auth.deliveryDetails);

  const checkoutCallback = useCallback(() => {
    const getPapersHandler = async () => {
      try {
        const checkoutRes = await CarPapersAPi.cartCheckoutHandler({
          plate_number: personalData[0]?.vehicle_data[0]?.plate_number,
        });
        setCheckoutData(checkoutRes.data);
      } catch (err) {
        console.log(err?.response?.data?.error);
      }
    };
    getPapersHandler();
  }, [personalData]);

  useEffect(() => {
    checkoutCallback();
  }, [checkoutCallback]);

  const delivery = checkoutData.cart_list?.find((data, index) =>
    data?.product_title.includes("Delivery")
  );

  useEffect(() => {
    if (OTP.length >= 23) {
      const getCoupon = async () => {
        try {
          const couponRes = await CarPapersAPi.couponVerification({
            OTP,
            id: checkoutData?.customer_info[0]?.id,
          });
          if (couponRes.data === "Expired or Incorrect Coupon Code") {
            setModal(true);
            setOTP("");
          }
          setDiscount(couponRes.data[0]?.percentage);
        } catch (err) {
          console.log(err?.response?.data?.error);
        }
      };
      getCoupon();
    }
  }, [OTP, checkoutData?.customer_info]);

  const otpChangeHandler = (e) => {
    setOTP(e.target.value);
  };

  const bankToggleHandler = () => {
    let submitOrderHandler = async () => {
      setLoading(true);
      try {
        const submitOrderRes = await CarPapersAPi.submitOrderHandler({
          customer_id: checkoutData?.customer_info[0]?.id,
          firstname: personalData[0].fullname,
          lastname: "",
          street: deliveryData[0].street,
          location: deliveryData[0].location,
          service_delivery_date: "",
          order_type: "Papers",
          coupon_code: OTP || "",
          city: deliveryData[0].street,
          state: deliveryData[0].state,
          plateno: checkoutData?.customer_info[0]?.plate_no,
          phone: checkoutData?.customer_info[0]?.phone_no,
          discount: discount || 0,
          products: magento,
        });
        if (submitOrderRes.data.status === "success") {
          const generatedAccount = await CarPapersAPi.generateBankAccount({
            order: submitOrderRes.data.order,
          });
          console.log(generatedAccount.data);
          setGeneratedAccountData(generatedAccount.data);
          setBankDetails(true);
          dispatch(authActions.clearMagentoId());
        }
        if (submitOrderRes.data === "Order Already Exists, Check the Order") {
          setErrMsgModal(true);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        console.log(err?.response?.data?.error);
      }
    };
    submitOrderHandler();
  };
  const completeTransactionHandler = () => {
    navigate("/papers/success");
  };
  const backHandler = () => {
    setModal(false);
    setErrMsgModal(false);
  };
  const commafy = (num) => {
    return num?.toLocaleString();
  };

  return (
    <div>
      {modal && (
        <Modal
          title="Wrong or expired Coupon!"
          message="The coupon code you entered is either not valid or expired."
          back=""
          close={backHandler}
          accept=""
        />
      )}
      {errMsgModal && (
        <Modal2
          title="Error!"
          message="Order Already Exists, Check Order on history page"
          back=""
          close={backHandler}
          accept=""
        />
      )}
      <NavBar title="Invoice" setShowMenu={props.setShowMenu} />
      <div className={styles.head}>
        <div className={styles.main_hero}>
          <div className={styles.cartNav}>
            <p>CART</p>
            <p>DELIVERY</p>
            <p style={{ color: "#008069", borderBottom: "5px solid #008069" }}>
              PAYMENT
            </p>
          </div>
          <div className={styles.cartNav_progress}></div>
        </div>
        <div>
          {magento?.map((data, index) => (
            <input
              type="hidden"
              name="products"
              id="products[]"
              defaultValue={data}
            />
          ))}
        </div>
        <div className={styles.total2}>
          <div className={styles.headingCost}>
            <p>Total amount to be paid</p>
            <h4>
              ₦{" "}
              {commafy(
                checkoutData?.cart_sum -
                  (discount / 100) * checkoutData?.cart_sum
              ) || 0}
            </h4>
          </div>
          <div className={styles.grandDiscount}>
            <p>Discounted Amount</p>
            <h4>₦ {commafy((discount / 100) * checkoutData?.cart_sum || 0)}</h4>
          </div>
          <div>
            <p>Sub Total</p>
            <h4>₦{commafy(checkoutData?.cart_sum - delivery?.price)}</h4>
          </div>
          <div>
            <p>Delivery Fee</p>
            <h4>₦ {commafy(delivery?.price)}</h4>
          </div>
          <div className={styles.grandTotal}>
            <p>Grand Total</p>
            <h4>
              ₦{" "}
              {commafy(
                checkoutData?.cart_sum -
                  (discount / 100) * checkoutData?.cart_sum
              ) || 0}
            </h4>
          </div>
          <div className={styles.form}>
            <p>Enter discount code (optional)</p>
            <Input type="text" value={OTP} onChange={otpChangeHandler} />
          </div>
        </div>
        {!bankDetails && (
          <div className={styles.button2}>
            {!loading && (
              <Button onClick={bankToggleHandler}>Pay via bank transfer</Button>
            )}
            {loading && <Button disabled="false">Loading...</Button>}
          </div>
        )}
        {bankDetails && (
          <div className={styles.bankDetails}>
            <h3>Here is the account details you are to make transfer to.</h3>
            <h2>{generatedAccountData.account_number}</h2>
            <h3>Account name: {generatedAccountData.account_name}</h3>
            <h3>Bank Name: {generatedAccountData.bank_name}</h3>
            <div className={styles.button}>
              <Button onClick={completeTransactionHandler}>
                Wait to verify the transaction
              </Button>
            </div>
          </div>
        )}
      </div>
      <BottomNav />
    </div>
  );
}

export default Invoice;
