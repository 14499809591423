const SpareParts = [
  {
    title: "Shock Absorber",
    name: "Toyota",
    model: "ST223",
    id: "000012",
  },
  {
    title: "Shock Absorber",
    name: "Toyota",
    model: "ST223",
    id: "000013",
  },
  {
    title: "Shock Absorber",
    name: "Toyota",
    model: "ST223",
    id: "000014",
  },
  {
    title: "Shock Absorber",
    name: "Toyota",
    model: "ST223",
    id: "000015",
  },
  {
    title: "Shock Absorber",
    name: "Toyota",
    model: "ST223",
    id: "000016",
  },
  {
    title: "Shock Absorber",
    name: "Toyota",
    model: "ST223",
    id: "000017",
  },
  {
    title: "Shock Absorber",
    name: "Toyota",
    model: "ST223",
    id: "000018",
  },
];

export default SpareParts;
