import React, { useState, useEffect } from "react";
import styles from "./ForgotPassword.module.css";
import PasswordInput from "../../Components/interface/PasswordInput";
import Button from "../../Components/interface/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import BottomNav from "../../Components/BottomNav";
import Authentication from "../../utils/api/Authentication";

const initialValues = {
  password: "",
  secondPassword: "",
};
const initialBoolenValues = {
  toggle: false,
  passwordInvalid: false,
  confirmPasswordInValid: false,
  secondToggle: false,
  formIsValid: false,
};

function ResetPassword() {
  const [values, setValues] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [boolen, setBoolen] = useState(initialBoolenValues);

  const personalData = useSelector((data) => data.auth);

  useEffect(() => {
    if (
      values.password.trim().length >= 1 &&
      values.secondPassword.trim().length >= 1 &&
      values.password === values.secondPassword
    ) {
      setBoolen({ formIsValid: true });
    } else {
      setBoolen({ formIsValid: false });
    }
  }, [values.password, values.secondPassword]);

  let submitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (values.password === values.secondPassword) {
        const resetPasswordRes = await Authentication.resetPassword({
          user_id: personalData.generalLoginData[0].id,
          password: values.password,
          access_token: personalData.token,
        });
        console.log(resetPasswordRes.data);
        // toast.success(resetPasswordRes.data., {
        //   position: "top-left",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "dark",
        // });
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Passwords do not match.", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err.response.data.errors, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  let passwordToggleHandler = () => {
    setBoolen({ ...boolen, toggle: !boolen.toggle });
  };

  let confirmPasswordToggle = () => {
    setBoolen({ ...boolen, secondToggle: !boolen.secondToggle });
  };

  let confirmPasswordInputBlur = () => {
    if (values.secondPassword.trim().length === 0) {
      setBoolen({ ...boolen, confirmPasswordInValid: true });
    } else {
      setBoolen({ ...boolen, confirmPasswordInValid: false });
    }
  };

  let passwordInputBlur = () => {
    if (values.password.trim().length === 0) {
      setBoolen({ ...boolen, passwordInvalid: true });
    } else {
      setBoolen({ ...boolen, passwordInvalid: false });
    }
  };

  let type = boolen.toggle ? "name" : "password";
  let type2 = boolen.secondToggle ? "name" : "password";
  return (
    <section className={styles.login}>
      <ToastContainer />
      <div className={styles.login__hero}>
        <div className={styles.header}>
          <p>Reset Password</p>
          <p>Enter a new password.</p>
        </div>
      </div>
      <div className={styles.form}>
        <form onSubmit={submitHandler}>
          <PasswordInput
            toggle={boolen.toggle}
            label="New Password"
            onClick={passwordToggleHandler}
            placeholder="Enter password"
            type={type}
            onBlur={passwordInputBlur}
            passwordInvalid={boolen.passwordInvalid}
            onChange={handleInputChange}
            value={values.password}
            name="password"
          />
          <PasswordInput
            toggle={boolen.secondToggle}
            label="Confirm New Password"
            onClick={confirmPasswordToggle}
            placeholder="Enter password"
            type={type2}
            onBlur={confirmPasswordInputBlur}
            passwordInvalid={boolen.confirmPasswordInValid}
            onChange={handleInputChange}
            name="secondPassword"
            value={values.secondPassword}
          />
          {!loading && <Button disabled={!boolen.formIsValid}>Submit</Button>}
          {loading && <Button disabled={boolen.formIsValid}>Loading...</Button>}
        </form>
      </div>
      <BottomNav />
    </section>
  );
}

export default ResetPassword;
