import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./Insurance.module.css";

function InsuranceMenu(props) {
  return (
    <div className={styles.nav}>
      <NavLink
        className={(navData) => (navData.isActive ? `${styles.active}` : "")}
        to={`/${props.first_link}`}
      >
        <div className={styles.nav_insurance}>
          <p>{props.first}</p>
        </div>
      </NavLink>

      <NavLink
        className={(navData) => (navData.isActive ? `${styles.active}` : "")}
        to={`/${props.second_link}`}
      >
        <div className={styles.nav_claims}>
          <p>{props.second}</p>
        </div>
      </NavLink>
    </div>
  );
}

export default InsuranceMenu;
