import React from "react";
import Card from "../UI/Card";
import styles from "../UI/Modal.module.css";

function ScanForm(props) {
  return (
    <div>
      <div className={styles.backdrop} onClick={props.closeModalHandler}>
        <Card
          className={styles.modal}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {props.children}
        </Card>
      </div>
    </div>
  );
}

export default ScanForm;
