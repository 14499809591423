import React from "react";
import { useSelector } from "react-redux";
import Transactions from "../../Components/customer-profile/Transactions";
import styles from "./Account.module.css";

function CreditTransaction(props) {
  const wallet = useSelector((data) => data.auth.wallet);
  const creditTransaction = wallet?.results?.filter((data, index) => {
    return data?.transaction_type === "CREDIT";
  });

  return (
    <div className={styles.transactionList_wrapper}>
      {creditTransaction?.length === 0 ? (
        <div>
          <h4>You have not made any transaction🙂</h4>
        </div>
      ) : (
        <div>
          {creditTransaction?.map((data, index) => {
            const creditData =
              data?.transaction_type === "DEBIT" ? false : true;
            return <Transactions credit={creditData} product={data} />;
          })}{" "}
        </div>
      )}
    </div>
  );
}

export default CreditTransaction;
