import React from "react";
import styles from "../../Pages/home/home/BuyDongle.module.css";

function VehicleDetails(props) {
  return (
    <div className={`${styles.vehicleDetails} ${props.className}`}>
      <div>
        <h4>
          {props.vehicle_make} {props.vehicle_model}
        </h4>
        <p>{props.vehicle_year} </p>
      </div>
      <div>
        <p>VIN: {props.vin}</p>
        <p>{props.plate_number}</p>
      </div>
    </div>
  );
}

export default VehicleDetails;
