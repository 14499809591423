import React from "react";
import styles from "./Recents.module.css";

function Transaction() {
  return (
    <div className={styles.history}>
      <div className={styles.list}>
        <div>
          <p className={styles.title}>Transaction</p>
          <h5>Premium paper</h5>
          <p>Subscription</p>
        </div>
        <div>
          <p>10/09/2020 22:28</p>
          <h5>₦ 17,000</h5>
          <p>Amount</p>
        </div>
      </div>

      <div className={styles.list}>
        <div>
          <p className={styles.title}>Transaction</p>
          <h5>Premium paper</h5>
          <p>Subscription</p>
        </div>
        <div>
          <p>10/09/2020 22:28</p>
          <h5>₦ 17,000</h5>
          <p>Amount</p>
        </div>
      </div>

      <div className={styles.list}>
        <div>
          <p className={styles.title}>Transaction</p>
          <h5>Premium paper</h5>
          <p>Subscription</p>
        </div>
        <div>
          <p>10/09/2020 22:28</p>
          <h5>₦ 17,000</h5>
          <p>Amount</p>
        </div>
      </div>

      <div className={styles.list}>
        <div>
          <p className={styles.title}>Transaction</p>
          <h5>Premium paper</h5>
          <p>Subscription</p>
        </div>
        <div>
          <p>10/09/2020 22:28</p>
          <h5>₦ 17,000</h5>
          <p>Amount</p>
        </div>
      </div>
    </div>
  );
}

export default Transaction;
