import React from 'react'
import styles from '../../Pages/home/myCar/LiveMetrics.module.css'

function DashBoardHeader(props) {
    return (
        <div className={styles.header}>
            <div className={styles.signs}>
                <div>
                    <div className={styles.green}></div>
                    <span>Fine</span>
                </div>
                <div>
                    <div className={styles.red}></div>
                    <span>Danger</span>
                </div>
                <div>
                    <div className={styles.yellow}></div>
                    <span>Warning</span>
                </div>
            </div>
        </div>
    )
}

export default DashBoardHeader