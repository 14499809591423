import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styles from "./NavBar.module.css";

function NavBar() {
  const [showOrderMenu, setShowOrderMenu] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/orders") {
      setShowOrderMenu(true);
    } else {
      setShowOrderMenu(false);
    }
  }, [pathname]);

  return (
    <div className={styles.navLinks}>
      {showOrderMenu && (
        <NavLink
          to="/orders"
          className={(navData) => (navData.isActive ? `${styles.active}` : "")}
        >
          Orders
        </NavLink>
      )}
      {!showOrderMenu && (
        <NavLink
          to="/history/diagnosis"
          className={(navData) => (navData.isActive ? `${styles.active}` : "")}
        >
          Diagnosis
        </NavLink>
      )}
      {/* <NavLink
        to="/history/transaction"
        className={(navData) => (navData.isActive ? `${styles.active}` : "")}
      >
        Transaction
      </NavLink> */}
    </div>
  );
}

export default NavBar;
