import { createSlice } from "@reduxjs/toolkit";

const defaultParts = {
  products: [],
  totalQuantity: 0,
};

const sparePartsSlice = createSlice({
  name: "dongle",
  initialState: defaultParts,
  reducers: {
    addProduct(state, action) {
      const newProduct = action.payload;
      const existingCartItem = state.products.find(
        (item) => item.id === newProduct.id
      );
      if (!existingCartItem) {
        state.products.push({
          id: newProduct.id,
          title: newProduct.title,
          model: newProduct.model,
          name: newProduct.name,
          quantity: newProduct.quantity,
        });
      } else {
        existingCartItem.quantity = existingCartItem.quantity + 1;
      }
      state.totalQuantity = state.totalQuantity + newProduct.quantity;
    },
    removeProduct(state, action) {
      const id = action.payload;
      const existingCartItem = state.products.find((item) => item.id === id);
      state.totalQuantity = state.totalQuantity - 1;
      if (existingCartItem.quantity === 1) {
        state.products = state.products.filter((item) => item.id !== id);
      } else {
        existingCartItem.quantity = existingCartItem.quantity - 1;
      }
    },
  },
});

export const partsActions = sparePartsSlice.actions;
export default sparePartsSlice;
