import React from 'react'
import styles from './Logo.module.css'
import logo from '../../asset/images/logo.png'

function Logo() {
    return (
        <div className={styles.wrapper}>
            <img src={logo} alt='MotorMata-logo'/>
        </div>
    )
}

export default Logo