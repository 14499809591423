import React, { useEffect, useState } from "react";
import styles from "./Account.module.css";
import Logo from "../../Components/interface/Logo";
import Button from "../../Components/interface/Button";
import Calender from "../../Components/inputs/Calender";
import { NavLink } from "react-router-dom";
import { Outlet } from "react-router";
import { useSelector } from "react-redux";
// import VendorApi from "../../utils/api/VendorApi";
import Modal from "../../Components/UI/Modal";

function Wallet(props) {
  // const { walletDataHandler } = props;
  const [startDate, setStartDate] = useState(null);
  const [endFilterDate, setEndFilterDate] = useState(null);
  const [validButton, setValidButton] = useState(false);
  const [modal, setModal] = useState(false);
  // const [dashboardData, setDashboardData] = useState([]);
  const [greeting, setGreeting] = useState("");
  // const [refreshModal, setRefreshModal] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);

  // const token = useSelector((data) => data.auth.token);
  const vendorInfo = useSelector((data) => data.auth.vendorSignInData);
  let personalData = useSelector((data) => data.auth.loginData);

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const day = currentDate.getDate();
  const date = `${year}-${month}-${day}`;

  useEffect(() => {
    if (startDate !== null && endFilterDate !== null) {
      setValidButton(true);
    } else {
      setValidButton(false);
    }
  }, [startDate, endFilterDate]);

  // useEffect(() => {
  //   const fetchVendorData = async () => {
  //     try {
  //       setIsLoading(true);
  //       const res = VendorApi.vendorDashboardData(token);
  //       res.then((response) => {
  //         setDashboardData(response.data);
  //       });
  //       setTimeout(() => {
  //         setIsLoading(false);
  //       }, 2000);
  //     } catch (error) {
  //       setTimeout(() => {
  //         setIsLoading(false);
  //       }, 2000);
  //       console.log(error.response.data.errors);
  //     }
  //   };
  //   fetchVendorData();
  // }, [token]);

  // const openPaymentHandler = () => {
  //   setModal(true);
  // };

  const backHandler = () => {
    setModal(false);
  };

  useEffect(() => {
    const now = new Date();
    const hour = now.getHours();

    if (hour >= 5 && hour < 12) {
      setGreeting("Good morning ☀️");
    } else if (hour >= 12 && hour < 18) {
      setGreeting("Good afternoon 🌤️");
    } else {
      setGreeting("Good night 🌙");
    }
  }, []);

  // const refreshPageHandler = () => {
  //   setRefreshModal(true);
  //   walletDataHandler();
  //   setTimeout(() => {
  //     setRefreshModal(false);
  //   }, 2000);
  // };

  return (
    <div>
      {/* {isLoading && <div className={styles.loading}></div>} */}
      {/* {!isLoading && ( */}
      <div>
        {/* {refreshModal && ( */}
        {/* <Modal title="Refreshing..." message="Just a sec" accept="" back="" /> */}
        {/* )} */}
        {modal && (
          <Modal
            title="Payment"
            message="Here is the account details you are to pay to."
            largeMsg={vendorInfo[0]?.funding_account_number}
            largeMsg2={`Account name: ${vendorInfo[0]?.funding_account_name}`}
            largeMsg3={`Bank: ${vendorInfo[0]?.funding_bank_name}`}
            accept="Back"
            back=""
            close={backHandler}
            confirm={backHandler}
          />
        )}
        <div className={styles.walletCover}>
          <div className={styles.heading_text}>
            <p>
              {greeting}, {personalData[0].fullname}!
            </p>
          </div>
          <div className={styles.card}>
            <div className={styles.card_inner}>
              <div className={styles.card_inner_balance}>
                <p>Balance</p>
                <h2>
                  {" "}
                  ₦{Number(0).toLocaleString()}
                  .00
                </h2>
              </div>
              <div className={styles.card_inner_logo}>
                <Logo />
              </div>
            </div>

            <div className={styles.card_inner}>
              <div className={styles.card_inner_balance}>
                <p>Wallet ID</p>
                <p>• • • • • • • • • 8399</p>
              </div>
              <div className={styles.card_inner_logo}>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.859468"
                    d="M16 32C24.8369 32 32 24.8365 32 16C32 7.16309 24.8369 0 16 0C7.16356 0 0 7.16309 0 16C0 24.8365 7.16356 32 16 32Z"
                    fill="#FF9900"
                  />
                </svg>
                <svg
                  width="33"
                  height="32"
                  viewBox="0 0 33 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.401246"
                    d="M16.1362 32C24.9727 32 32.1362 24.8365 32.1362 16C32.1362 7.16309 24.9727 0 16.1362 0C7.29979 0 0.13623 7.16309 0.13623 16C0.13623 24.8365 7.29979 32 16.1362 32Z"
                    fill="#E2FBD7"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className={styles.options}>
            <div className={styles.options_body}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 19.9996V14.9996H15M4 3.99959V8.99959H9M19.938 10.9996C19.7287 9.337 19.0026 7.78209 17.8623 6.55423C16.7219 5.32638 15.2248 4.48756 13.5822 4.15612C11.9396 3.82468 10.2343 4.01734 8.70709 4.70694C7.17983 5.39654 5.90764 6.54825 5.07 7.99959M4.062 12.9996C4.27133 14.6622 4.99739 16.2171 6.13774 17.445C7.2781 18.6728 8.7752 19.5116 10.4178 19.8431C12.0604 20.1745 13.7657 19.9818 15.2929 19.2922C16.8202 18.6027 18.0924 17.4509 18.93 15.9996"
                  stroke="#008069"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p>Refresh</p>
            </div>

            <div className={styles.options_body}>
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.5 21.3333H18.8333V21V17V16.6667H18.5H6.5H6.16667V17V21V21.3333H6.5H18.5ZM18.5 15.3333H18.8333V15V13V12.6667H18.5H6.5H6.16667V13V15V15.3333H6.5H18.5ZM10.38 5.33333H17.1667V9.66667H16.3333V6.5V6.16667H16H10.38H9.5776L10.1438 6.73522L12.3302 8.93062L11.7392 9.52721L7.97085 5.75L11.7392 1.97279L12.3302 2.56938L10.1438 4.76478L9.5776 5.33333H10.38ZM6.5 11.3333H18.5C18.942 11.3333 19.366 11.5089 19.6785 11.8215C19.9911 12.134 20.1667 12.558 20.1667 13V21C20.1667 21.442 19.9911 21.8659 19.6785 22.1785C19.366 22.4911 18.942 22.6667 18.5 22.6667H6.5C6.05797 22.6667 5.63405 22.4911 5.32149 22.1785C5.00893 21.8659 4.83333 21.442 4.83333 21V13C4.83333 12.558 5.00893 12.134 5.32149 11.8215C5.63405 11.5089 6.05797 11.3333 6.5 11.3333Z"
                  fill="#008069"
                  stroke="#008069"
                  stroke-width="0.666667"
                />
              </svg>
              <p>Top up</p>
            </div>
          </div>
          <div className={styles.calender_filter}>
            <Calender
              placeholderText="Start Date"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              openToDate={new Date(date)}
            />

            <Calender
              placeholderText="End Date"
              selected={endFilterDate}
              onChange={(date) => setEndFilterDate(date)}
              openToDate={new Date(date)}
            />
            <div className={styles.filterButton}>
              <Button disabled={!validButton}>
                <div>
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.95062 15.4844H6.51562V7.93213H6.52041L6.51196 7.92208L1.26562 1.67643V0.515625H15.4844V1.66989L10.4878 7.91561L10.4844 7.91288V7.92537V12.9506L7.95062 15.4844ZM7.48438 14.5V14.5156H7.5H7.54291V14.5221L7.55395 14.511L9.51105 12.554L9.51562 12.5585V12.5429V7.58011L14.3719 1.50976L14.3922 1.48438H14.3597H2.40313H2.36959L2.39116 1.51005L7.48438 7.57357V14.5Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.03125"
                    />
                  </svg>
                  Filter
                </div>
              </Button>
            </div>
          </div>
          <div className={styles.transactions}>
            <div className={styles.heading_text}>
              <p>Transaction History</p>
            </div>
            <div className={styles.transactions_options}>
              <NavLink
                to="/account/recent-transaction"
                className={(navData) =>
                  navData.isActive ? `${styles.active}` : ""
                }
              >
                <p>Recent</p>
              </NavLink>
              <NavLink
                to="/account/credit-transaction"
                className={(navData) =>
                  navData.isActive ? `${styles.active}` : ""
                }
              >
                <p>Credit</p>
              </NavLink>
              <NavLink
                to="/account/debit-transaction"
                className={(navData) =>
                  navData.isActive ? `${styles.active}` : ""
                }
              >
                <p>Debit</p>
              </NavLink>
            </div>
          </div>
          <Outlet />
        </div>
      </div>
      {/* )} */}
    </div>
  );
}

export default Wallet;
