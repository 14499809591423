import React from "react";
import styles from "./VehicleFaultCodes.module.css";
import NoErrorCode from "../UI/NoErrorCode";

function VehicleFaultCodes(props) {
  return (
    <div className={styles.body}>
      <div className={styles.system_codes2}>
        {props.liveMetrics?.scan_data.vehicle_errors.length !== 0 ? (
          <div className={styles.system_codes_details2}>
            <div className={styles.err_header}>
              <div>
                <p>Error Code:</p>
                <p>{props.title}</p>
              </div>
              <div>
                <p>Condition:</p>
                <p>Risk</p>
              </div>
            </div>
            <div className={styles.details}>
              <h4>Details</h4>
              <p>{props.description}.</p>
            </div>
          </div>
        ) : (
          <NoErrorCode />
        )}
      </div>
    </div>
  );
}

export default VehicleFaultCodes;
