import React, { useEffect, useState } from "react";
import styles from "./VehicleInfo2.module.css";
import { useSelector } from "react-redux";
import Images from "../../utils/CarImages";
import carImg from "../../asset/images/car.png";
// import { NavLink } from "react-router-dom";
import CustomerScans from "../../utils/api/CustomerScans";

function VehicleInfo2(props) {
  const [vehicleInfo, setVehicleInfo] = useState(null);
  const [carState, setCarState] = useState("");
  const vehicleIndex = useSelector((data) => data.dongles.currentVehicle);

  const dongle = useSelector((data) => data.dongles);

  const personalData = useSelector((data) => data.auth);

  const liveMetrics = dongle.liveMetrics[0];

  const vehiclesIndex = useSelector((data) => data.dongles.currentVehicle);

  const vehicle_data =
    personalData.generalLoginData[0]?.vehicle_data.length !== 0
      ? personalData.generalLoginData[0]?.vehicle_data
      : personalData.generalLoginData[0]?.vendor_vehicle_data;

  useEffect(() => {
    const fetchYmmData = async () => {
      try {
        const ymmRes = await CustomerScans.vinDecoder({
          token: props.token,
          vin: props?.liveMetrics?.scan_data?.vehicle_vin,
        });
        const vehicle_data_info =
          ymmRes.data.vehicle_make === ""
            ? vehicle_data[vehiclesIndex]
            : ymmRes.data;
        setVehicleInfo(vehicle_data_info);
      } catch (err) {
        console.log(err?.response?.error);
      }
    };
    fetchYmmData();
  }, [
    props?.liveMetrics?.scan_data?.vehicle_vin,
    props.token,
    vehicle_data,
    vehiclesIndex,
  ]);

  useEffect(() => {
    if (liveMetrics?.scan_data?.vehicle_live_data?.online !== true) {
      setCarState("Offline");
    } else if (
      liveMetrics?.scan_data?.vehicle_live_data?.online === true &&
      liveMetrics?.scan_data?.vehicle_live_data?.speed === 0
    ) {
      setCarState("Parked");
    } else {
      setCarState("Online");
    }
  }, [
    liveMetrics?.scan_data?.vehicle_live_data?.online,
    liveMetrics?.scan_data?.vehicle_live_data?.speed,
  ]);

  const carImage = Images?.find(
    (data) =>
      data.name?.toUpperCase() ===
      `${
        vehicleInfo?.vehicle_year
      }-${vehicleInfo?.vehicle_make?.toUpperCase()}-${vehicleInfo?.vehicle_model?.toUpperCase()}`
  );

  return (
    <div className={styles.VehicleInfo}>
      <div className={styles.VehicleInfo_inner}>
        <div>
          <p>Vehicle Infomation</p>
        </div>
        <div>
          <div className={styles.online_status}>
            {props.onlineStatus ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.9999 13.5999C8.88356 13.5999 9.5999 12.8836 9.5999 11.9999C9.5999 11.1162 8.88356 10.3999 7.9999 10.3999C7.11625 10.3999 6.3999 11.1162 6.3999 11.9999C6.3999 12.8836 7.11625 13.5999 7.9999 13.5999Z"
                  fill="#008069"
                />
                <path
                  d="M0.800049 5.91997C1.70093 4.81334 2.80886 3.92705 4.05025 3.31997C5.29163 2.71289 6.63804 2.39893 8.00005 2.39893C9.36205 2.39893 10.7085 2.71289 11.9499 3.31997C13.1912 3.92705 14.2992 4.81334 15.2 5.91997L14 7.03997C13.2493 6.09508 12.326 5.33832 11.2916 4.81997C10.2571 4.30162 9.13505 4.03354 8.00005 4.03354C6.86504 4.03354 5.74303 4.30162 4.70855 4.81997C3.67406 5.33832 2.75078 6.09508 2.00005 7.03997L0.800049 5.91997ZM3.76005 8.47997C4.28572 7.83026 4.94107 7.30807 5.68032 6.94986C6.41957 6.59164 7.2249 6.40606 8.04005 6.40606C8.8552 6.40606 9.66053 6.59164 10.3998 6.94986C11.139 7.30807 11.7944 7.83026 12.32 8.47997L11.2 9.59997C10.8252 9.10477 10.3503 8.7051 9.8103 8.43048C9.27029 8.15585 8.67899 8.01328 8.08005 8.01328C7.48111 8.01328 6.88981 8.15585 6.3498 8.43048C5.80979 8.7051 5.33486 9.10477 4.96005 9.59997L3.76005 8.47997Z"
                  fill="#008069"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="20"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.9999 13.5999C8.88356 13.5999 9.5999 12.8836 9.5999 11.9999C9.5999 11.1162 8.88356 10.3999 7.9999 10.3999C7.11625 10.3999 6.3999 11.1162 6.3999 11.9999C6.3999 12.8836 7.11625 13.5999 7.9999 13.5999Z"
                  fill="#8EA396"
                />
                <path
                  d="M0.800049 5.91997C1.70093 4.81334 2.80886 3.92705 4.05025 3.31997C5.29163 2.71289 6.63804 2.39893 8.00005 2.39893C9.36205 2.39893 10.7085 2.71289 11.9499 3.31997C13.1912 3.92705 14.2992 4.81334 15.2 5.91997L14 7.03997C13.2493 6.09508 12.326 5.33832 11.2916 4.81997C10.2571 4.30162 9.13505 4.03354 8.00005 4.03354C6.86504 4.03354 5.74303 4.30162 4.70855 4.81997C3.67406 5.33832 2.75078 6.09508 2.00005 7.03997L0.800049 5.91997ZM3.76005 8.47997C4.28572 7.83026 4.94107 7.30807 5.68032 6.94986C6.41957 6.59164 7.2249 6.40606 8.04005 6.40606C8.8552 6.40606 9.66053 6.59164 10.3998 6.94986C11.139 7.30807 11.7944 7.83026 12.32 8.47997L11.2 9.59997C10.8252 9.10477 10.3503 8.7051 9.8103 8.43048C9.27029 8.15585 8.67899 8.01328 8.08005 8.01328C7.48111 8.01328 6.88981 8.15585 6.3498 8.43048C5.80979 8.7051 5.33486 9.10477 4.96005 9.59997L3.76005 8.47997Z"
                  fill="#8EA396"
                />
              </svg>
            )}
            <p>{carState}</p>
          </div>
          {/* <NavLink
            to="/track"
            className={(navData) =>
              navData.isActive ? `${styles.active}` : ""
            }
          >
            {" "}
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5 6.5C12.5 5.30653 12.0259 4.16193 11.182 3.31802C10.3381 2.47411 9.19347 2 8 2C6.80653 2 5.66193 2.47411 4.81802 3.31802C3.97411 4.16193 3.5 5.30653 3.5 6.5C3.5 8.346 4.977 10.752 8 13.634C11.023 10.752 12.5 8.346 12.5 6.5ZM8 15C4.333 11.667 2.5 8.833 2.5 6.5C2.5 5.04131 3.07946 3.64236 4.11091 2.61091C5.14236 1.57946 6.54131 1 8 1C9.45869 1 10.8576 1.57946 11.8891 2.61091C12.9205 3.64236 13.5 5.04131 13.5 6.5C13.5 8.833 11.667 11.667 8 15Z"
                fill="#008069"
              />
              <path
                d="M8 8C8.39782 8 8.77936 7.84196 9.06066 7.56066C9.34196 7.27936 9.5 6.89782 9.5 6.5C9.5 6.10218 9.34196 5.72064 9.06066 5.43934C8.77936 5.15804 8.39782 5 8 5C7.60218 5 7.22064 5.15804 6.93934 5.43934C6.65804 5.72064 6.5 6.10218 6.5 6.5C6.5 6.89782 6.65804 7.27936 6.93934 7.56066C7.22064 7.84196 7.60218 8 8 8ZM8 9C7.33696 9 6.70107 8.73661 6.23223 8.26777C5.76339 7.79893 5.5 7.16304 5.5 6.5C5.5 5.83696 5.76339 5.20107 6.23223 4.73223C6.70107 4.26339 7.33696 4 8 4C8.66304 4 9.29893 4.26339 9.76777 4.73223C10.2366 5.20107 10.5 5.83696 10.5 6.5C10.5 7.16304 10.2366 7.79893 9.76777 8.26777C9.29893 8.73661 8.66304 9 8 9Z"
                fill="#008069"
              />
            </svg>
          </NavLink> */}
        </div>
      </div>
      <div className={styles.vehicle_information}>
        <div className={styles.vehicle_information_image}>
          <div className={styles.vehicle_information_image_wrapper}>
            <img src={carImage?.image || carImg} alt="car" />
          </div>
        </div>
        <div className={styles.vehicle_information_about}>
          <div className={styles.vehicle_information_about_list}>
            <p>Brand</p>
            <p>{vehicleInfo?.vehicle_make?.toUpperCase()}</p>
          </div>

          <div className={styles.vehicle_information_about_list}>
            <p>Model</p>
            <p>{vehicleInfo?.vehicle_model?.toUpperCase()}</p>
          </div>

          <div className={styles.vehicle_information_about_list}>
            <p>Year</p>
            <p>{vehicleInfo?.vehicle_year}</p>
          </div>

          <div className={styles.vehicle_information_about_list}>
            <p>Plate No.</p>
            <p>
              {props?.data[0]?.vehicle_data[
                vehicleIndex
              ]?.plate_number?.toUpperCase() ||
                vehicleInfo?.plate_number?.toUpperCase()}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VehicleInfo2;
