import React, { useEffect, useState } from "react";
import Button from "../interface/Button";
import Card from "./Card";
import styles from "./Modal.module.css";
import Input from "../interface/Input";

function Ticket(props) {
  const [formIsValid, setFormIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState("");

  useEffect(() => {
    setLoading(false);
    if (location.length >= 3) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [location]);

  const subscriptionSubmitHandler = async () => {
    props.setSuccess(true);
  };

  const searchInputHandler = (e) => {
    setLocation(e.target.value);
  };
  return (
    <div className={styles.backdrop} onClick={props.close}>
      <Card
        className={styles.modal_subscription}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {props.children}
        <header>
          <h3 className={styles.header}>Booking Ticket</h3>
        </header>
        <div className={styles.content}>
          <p>
            Confirm that the car details displayed below are correct and book
            time and date for the service.
          </p>
        </div>
        <div className={styles.subscriptionOption2}>
          <Input
            placeholder="Enter Location"
            value={location}
            onChange={searchInputHandler}
            type="text"
            label="Name"
          />
        </div>

        <div className={styles.subscriptionOption2}>
          <Input
            placeholder="Enter Location"
            value={location}
            onChange={searchInputHandler}
            type="text"
            label="Plate Number"
          />
        </div>

        <div className={styles.subscriptionOption2}>
          <Input
            placeholder="Enter Location"
            value={location}
            onChange={searchInputHandler}
            type="text"
            label="Phone Number"
          />
        </div>
        <div className={styles.date_input}>
          <div className={styles.subscriptionOption2}>
            <Input
              placeholder="Select Date"
              value={location}
              onChange={searchInputHandler}
              type="date"
              label="Date"
            />
          </div>

          <div className={styles.subscriptionOption2}>
            <Input
              placeholder="Select a time"
              value={location}
              onChange={searchInputHandler}
              type="time"
              label="TIme"
            />
          </div>
        </div>

        <footer className={styles.actions2}>
          {!loading && (
            <Button
              onClick={subscriptionSubmitHandler}
              disabled={!formIsValid}
              type="submit"
              label
            >
              Book
            </Button>
          )}
          {loading && <Button disabled={formIsValid}>Loading...</Button>}
        </footer>
      </Card>
    </div>
  );
}

export default Ticket;
