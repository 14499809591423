import React, { useCallback, useEffect, useState } from "react";
import styles from "../home/papers/Papers.module.css";
import { useParams } from "react-router-dom";
import Card from "../../Components/UI/Card";
import NavBar from "../../Components/interface/NavBar";
import Button from "../../Components/interface/Button";

function OrderDetails(props) {
  const [data, setData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [bankDetails, setBankDetails] = useState(false);
  const [completedTrans, setCompletedTrans] = useState(false);
  const [generatedAccountData, setGeneratedAccountData] = useState([]);
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [msg, setMsg] = useState("");
  const { id } = useParams();
  useEffect(() => {
    const orderDetailsUrl = `https://bpms.motormata.com/api/v1/main/app/papers/orderDetails/${id}`;
    const detailsOrder = async () => {
      let response = await fetch(orderDetailsUrl, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const orderDetails = await response.json();
      if (response.ok) {
        setData(orderDetails);
        setOrderData(JSON.parse(orderDetails?.details?.order_details));
      } else {
        console.log(orderDetails);
      }
    };
    detailsOrder();
  }, [id]);
  const generateAcc = useCallback(() => {
    const accountGeneratorUrl = `https://bpms.motormata.com/api/v1/main/app/papers/generateAccount/${id}`;
    const generateAccount = async () => {
      let response = await fetch(accountGeneratorUrl, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const generatedAccount = await response.json();
      if (response.ok) {
        setGeneratedAccountData(generatedAccount);
      } else {
        console.log(data);
      }
    };
    generateAccount();
  }, [id, data]);

  useEffect(() => {
    if (data?.paymentlogs?.length >= 1) {
      setTransactionDetails(JSON.parse(data?.paymentlogs[0]?.response_log));
    }
  }, [data?.paymentlogs]);

  useEffect(() => {
    if (
      data?.paymentlogs?.length !== 0 &&
      transactionDetails[0]?.outstandingBalance !== 0
    ) {
      setMsg(
        `You have not made complete payment. You still have an outstanding of ₦${transactionDetails[0]?.outstandingBalance}.`
      );
    }
    if (
      transactionDetails[0]?.outstandingBalance === 0 &&
      data?.paymentlogs?.length >= 1
    ) {
      setCompletedTrans(true);
    }
    if (data?.paymentlogs?.length === 0) {
      setMsg("You have not made any payment. Pay to the account below");
    }
    if (data.account_details === null) {
      setBankDetails(false);
    } else {
      setBankDetails(true);
    }
  }, [data, orderData, transactionDetails]);
  useEffect(() => {
    generateAcc();
  }, [generateAcc]);
  const bankToggleHandler = () => {
    generateAcc();
    setBankDetails(true);
  };
  const Bckcolor =
    orderData?.status === `Pending` || `pending` ? "#FFF5CC" : "#E2FBD7";
  const color =
    orderData?.status === `Pending` || `pending` ? "#FD4D1E" : "#008069";
  const commafy = (num) => {
    return num?.toLocaleString();
  };

  function truncateTitle(input) {
    if (input.length > 8) {
      return input.substring(0, 21) + "";
    }
    return input;
  }
  return (
    <div>
      <NavBar title="Invoice" setShowMenu={props.setShowMenu} />
      <div className={styles.head}>
        <div className={styles.main_hero}>
          {!completedTrans && (
            <div className={styles.transaction_notifications}>
              <p>{msg}</p>
            </div>
          )}
          {bankDetails && !completedTrans && (
            <div className={styles.bankDetails}>
              <h3>Here is the account details you are to make transfer to.</h3>
              <h2>{generatedAccountData.account_number}</h2>
              <h3>Account name: {generatedAccountData.account_name}</h3>
              <h3>Bank Name: {generatedAccountData.bank_name}</h3>
            </div>
          )}
          <div className={styles.header}>
            <div className={styles.header_date}>
              <p>Date</p>
              <h5>{data?.details?.updated_at}</h5>
            </div>
            <div
              style={{ backgroundColor: Bckcolor }}
              className={styles.orderStatus}
            >
              <p style={{ color: color }}>{orderData?.status}</p>
            </div>
          </div>
          <div className={styles.deliveryHead}>
            <p>Delivery to</p>
          </div>
          <div className={styles.header}>
            <span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM17 19H7V18.76C8.42 17.62 10.16 17 12 17C13.84 17 15.58 17.62 17 18.76V19ZM19 17.86C17.2 16.09 14.73 15 12 15C9.27 15 6.8 16.09 5 17.86V5H19V17.86ZM12 13C13.93 13 15.5 11.43 15.5 9.5C15.5 7.57 13.93 6 12 6C10.07 6 8.5 7.57 8.5 9.5C8.5 11.43 10.07 13 12 13ZM12 8C12.83 8 13.5 8.67 13.5 9.5C13.5 10.33 12.83 11 12 11C11.17 11 10.5 10.33 10.5 9.5C10.5 8.67 11.17 8 12 8Z"
                  fill="#263238"
                />
              </svg>
              <p>{orderData?.customer_firstname}</p>
            </span>
            <span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.78937 2.26801L8.21223 1.83943C8.85993 1.64403 9.55661 1.69101 10.1722 1.9716C10.7878 2.2522 11.2803 2.74723 11.5577 3.36429L12.4431 5.33401C12.6817 5.86463 12.7481 6.45664 12.6331 7.02696C12.5182 7.59728 12.2275 8.11728 11.8019 8.51401L10.2548 9.95658C10.2357 9.97443 10.22 9.99564 10.2085 10.0191C10.0465 10.35 10.2925 11.2337 11.0631 12.5691C11.9322 14.0743 12.6034 14.6692 12.9145 14.5774L14.9451 13.956C15.5012 13.7862 16.0965 13.7946 16.6475 13.9802C17.1986 14.1657 17.6778 14.519 18.0179 14.9906L19.2762 16.7331C19.6713 17.2803 19.8543 17.9522 19.7912 18.6241C19.7281 19.296 19.4233 19.9222 18.9334 20.3863L17.8508 21.4106C17.4743 21.7672 17.0172 22.0274 16.5184 22.169C16.0196 22.3107 15.4939 22.3295 14.9862 22.224C11.9717 21.5966 9.2708 19.1691 6.86051 14.9949C4.44937 10.818 3.69766 7.26086 4.66623 4.33544C4.82836 3.84567 5.106 3.40212 5.4757 3.04228C5.84539 2.68243 6.29626 2.41686 6.79023 2.26801H6.78937ZM7.16137 3.49886C6.86498 3.58814 6.59443 3.74744 6.37259 3.96332C6.15074 4.1792 5.98412 4.4453 5.8868 4.73915C5.05194 7.26001 5.72823 10.4623 7.97394 14.352C10.2179 18.2391 12.6505 20.4249 15.2477 20.9649C15.5524 21.0282 15.868 21.0169 16.1674 20.9318C16.4668 20.8467 16.7412 20.6904 16.9671 20.4763L18.0488 19.4529C18.3127 19.203 18.477 18.8659 18.511 18.5041C18.5451 18.1423 18.4466 17.7804 18.2339 17.4857L16.9757 15.7423C16.7925 15.4884 16.5346 15.2982 16.2379 15.1983C15.9413 15.0984 15.6208 15.0938 15.3214 15.1852L13.2857 15.8083C12.1594 16.1434 11.0999 15.2049 9.94966 13.2111C8.97509 11.5243 8.63737 10.3054 9.05394 9.45429C9.13451 9.28972 9.24423 9.14144 9.37794 9.01629L10.9251 7.57372C11.1543 7.36011 11.3109 7.08007 11.3729 6.77291C11.4348 6.46576 11.399 6.14692 11.2705 5.86115L10.3851 3.89229C10.2357 3.55994 9.97052 3.29332 9.63896 3.14221C9.30741 2.9911 8.93219 2.96584 8.58337 3.07115L7.16051 3.49972L7.16137 3.49886Z"
                  fill="#263238"
                />
              </svg>
              <p>{orderData?.billing_address?.telephone}</p>
            </span>
          </div>
          <div className={styles.location}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.75 9.75C18.75 7.95979 18.0388 6.2429 16.773 4.97703C15.5071 3.71116 13.7902 3 12 3C10.2098 3 8.4929 3.71116 7.22703 4.97703C5.96116 6.2429 5.25 7.95979 5.25 9.75C5.25 12.519 7.4655 16.128 12 20.451C16.5345 16.128 18.75 12.519 18.75 9.75ZM12 22.5C6.4995 17.5005 3.75 13.2495 3.75 9.75C3.75 7.56196 4.61919 5.46354 6.16637 3.91637C7.71354 2.36919 9.81196 1.5 12 1.5C14.188 1.5 16.2865 2.36919 17.8336 3.91637C19.3808 5.46354 20.25 7.56196 20.25 9.75C20.25 13.2495 17.5005 17.5005 12 22.5Z"
                fill="#263238"
              />
              <path
                d="M12 12C12.5967 12 13.169 11.7629 13.591 11.341C14.0129 10.919 14.25 10.3467 14.25 9.75C14.25 9.15326 14.0129 8.58097 13.591 8.15901C13.169 7.73705 12.5967 7.5 12 7.5C11.4033 7.5 10.831 7.73705 10.409 8.15901C9.98705 8.58097 9.75 9.15326 9.75 9.75C9.75 10.3467 9.98705 10.919 10.409 11.341C10.831 11.7629 11.4033 12 12 12ZM12 13.5C11.0054 13.5 10.0516 13.1049 9.34835 12.4017C8.64509 11.6984 8.25 10.7446 8.25 9.75C8.25 8.75544 8.64509 7.80161 9.34835 7.09835C10.0516 6.39509 11.0054 6 12 6C12.9946 6 13.9484 6.39509 14.6517 7.09835C15.3549 7.80161 15.75 8.75544 15.75 9.75C15.75 10.7446 15.3549 11.6984 14.6517 12.4017C13.9484 13.1049 12.9946 13.5 12 13.5Z"
                fill="#263238"
              />
            </svg>
            <p>{orderData?.billing_address?.region}</p>
            <p>{orderData?.billing_address?.street}</p>
          </div>
        </div>
        {orderData?.items?.map((data, index) => (
          <Card>
            <div className={styles.vehiclePapers}>
              <div className={styles.papers_title}>
                <h4>{truncateTitle(data.name)}</h4>
                <p>Car Document</p>
              </div>
              <div>
                <h3>₦{commafy(data.price)}</h3>
              </div>
            </div>
          </Card>
        ))}
        <div className={styles.total2}>
          <div>
            <p>Sub Total</p>
            <h4>₦{commafy(orderData.grand_total)}</h4>
          </div>
          <div className={styles.grandDiscount}>
            <p>Discounted Amount</p>
            <h4>₦ {commafy(orderData.discount_amount) || 0}</h4>
          </div>

          <div className={styles.grandTotal}>
            <p>Grand Total</p>
            <h4>₦ {commafy(orderData.grand_total)}</h4>
          </div>
        </div>
        {!bankDetails && (
          <div>
            <Button onClick={bankToggleHandler}>Pay via bank transfer</Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default OrderDetails;
