import React, { useEffect, useState } from "react";
import Button from "../interface/Button";
import Card from "./Card";
import styles from "./Modal.module.css";

const list = [
  "Premium Routine Services",
  "Standard Routine Services",
  "Basic Routine Services",
];

function SubscriptionModal(props) {
  const [formIsValid, setFormIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clickedItem, setClickedItem] = useState(0);
  // const [plans, setPlans] = useState(list);

  useEffect(() => {
    setLoading(false);
    if (clickedItem >= 0) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [clickedItem]);

  const subscriptionToggleHandler = (index) => {
    setClickedItem(index);
  };

  const subscriptionSubmitHandler = async () => {
    props.onClick();
    setClickedItem(0);
  };
  return (
    <div className={styles.backdrop} onClick={props.close}>
      <Card
        className={styles.modal_subscription}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {props.children}
        <header>
          <h3 className={styles.header}>Routine Services</h3>
        </header>
        <div className={styles.content}>
          <p>
            There are three classes of routine services. Select and book the
            routine service of your choice.
          </p>
        </div>
        <div className={styles.subscriptionOption}>
          {list?.map((data, index) => {
            return (
              <div
                onClick={() => {
                  subscriptionToggleHandler(index);
                }}
                // style={{ backgroundColor: color }}
                className={
                  clickedItem === index
                    ? styles.subscriptionOption_list
                    : styles.subscriptionOption_list_normal
                }
              >
                <p>{data}</p>
                {clickedItem === index ? (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 1H5C2.79086 1 1 2.79086 1 5V15C1 17.2091 2.79086 19 5 19H15C17.2091 19 19 17.2091 19 15V5C19 2.79086 17.2091 1 15 1Z"
                      fill="#168912"
                      stroke="white"
                      stroke-width="1.33333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path d="M7 10L9.25 12L13 8" fill="#168912" />
                    <path
                      d="M7 10L9.25 12L13 8"
                      stroke="white"
                      stroke-width="1.33333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 1H5C2.79086 1 1 2.79086 1 5V15C1 17.2091 2.79086 19 5 19H15C17.2091 19 19 17.2091 19 15V5C19 2.79086 17.2091 1 15 1Z"
                      fill="white"
                      stroke="#168912"
                      stroke-width="1.33333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path d="M7 10L9.25 12L13 8" fill="white" />
                    <path
                      d="M7 10L9.25 12L13 8"
                      stroke="#168912"
                      stroke-width="1.33333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                )}
              </div>
            );
          })}
        </div>

        <footer className={styles.actions2}>
          {!loading && (
            <Button
              onClick={subscriptionSubmitHandler}
              disabled={!formIsValid}
              type="submit"
            >
              Proceed
            </Button>
          )}
          {loading && <Button disabled={formIsValid}>Loading...</Button>}
        </footer>
      </Card>
    </div>
  );
}

export default SubscriptionModal;
