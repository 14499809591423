import React from "react";
import styles from "./VehicleInfo.module.css";
import { useSelector } from "react-redux";
import VehicleInformation2 from "../UI/VehicleInformation2";

function VehicleInfo({ info }) {
  const personalData = useSelector((data) => data.auth);

  const dongle = useSelector((data) => data.dongles);

  const vehicle_data =
    personalData.generalLoginData[0]?.vehicle_data.length !== 0
      ? personalData.generalLoginData[0]?.vehicle_data
      : personalData.generalLoginData[0]?.vendor_vehicle_data;

  return (
    <div className={styles.home_live_metrics}>
      {/* <div className={styles.head}>
        <div className={styles.header_text}>
          <h3>Vehicle Infomation</h3>
        </div>
      </div> */}
      <div>
        <VehicleInformation2
          multi_vehicles="true"
          vehicle_make={vehicle_data[dongle.currentVehicle]?.vehicle_make}
          vehicle_model={vehicle_data[dongle.currentVehicle]?.vehicle_model}
          vehicle_year={vehicle_data[dongle.currentVehicle]?.vehicle_year}
          vin="No VIN"
          plate_number={vehicle_data[dongle.currentVehicle]?.plate_number}
        />
        {/* <AddedVehicles /> */}
      </div>
      {/* <div className={styles.info}>
        <h4>Model:</h4>
        <p>
          {info?.vehicle_year} {info?.vehicle_model}
        </p>
      </div>

      <div className={styles.info}>
        <h4>VIN:</h4>
        <p>Not available</p>
      </div>

      <div className={styles.info}>
        <h4>Fuel Tank:</h4>
        <p>20 miles / gallon</p>
      </div>

      <div className={styles.info}>
        <h4>Engine Type:</h4>
        <p>2AR-FE 2.5</p>
      </div> */}
    </div>
  );
}

export default VehicleInfo;
