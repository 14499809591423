import React, { useEffect, useState } from "react";
import styles from "./Maintenance.module.css";
import BottomNav from "../BottomNav";
import Button from "../interface/Button";
import NormalButton from "../interface/NormalButton";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Modal from "../UI/Modal";

function Maintenance() {
  const [routine, setRoutine] = useState(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  // Fetch routine maintenance from airtable
  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://api.airtable.com/v0/appJt0Pp9p2hwxFxf/maintenance`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_AIR_TABLE_KEY}`,
        },
      })
      .then((response) => {
        const maintenance = response.data.records.filter(
          (record) => record.id === id
        );
        setRoutine(maintenance[0]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.response.data.error.message);
      });
  }, [id]);

  return (
    <div>
      {loading && (
        <Modal message="Loading..." back="" accept="" loader="true" />
      )}
      <div className={styles.body}>
        <div className={styles.header}>
          <p>Maintenance</p>
        </div>
        <div className={styles.maintenance_banner}>
          <div>
            <h3>{routine?.fields?.Name} Due</h3>
          </div>
          <div>
            <p>Due Date </p>
            <p>{routine?.fields?.Due_date}</p>
          </div>
        </div>

        <div className={styles.image_wrapper}>
          <div className={styles.image_wrapper_title}>
            <p>Image</p>
          </div>

          <div className={styles.image_wrapper_inner}></div>
        </div>
        <div className={styles.add_info}>
          <div>
            <div className={styles.image_wrapper_title}>
              {" "}
              <p>Attention!</p>
            </div>
            <div className={styles.image_wrapper_title}>
              <h2>{routine?.fields?.About}</h2>
            </div>
          </div>

          <div>
            <div className={styles.image_wrapper_title}>
              {" "}
              <p>Has it been fixed?</p>
            </div>

            <div className={styles.image_wrapper_title}>
              {" "}
              <h2>
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint. Velit officia consequat duis enim velit mollit.
                Exercitation veniam consequat sunt nostrud amet
              </h2>{" "}
            </div>
          </div>
        </div>

        <div className={styles.buttons}>
          <Link>
            <Button>Request for a call</Button>
          </Link>
          <Link to="/">
            {" "}
            <NormalButton className={styles.greenButton}>
              The fault has been fixed
            </NormalButton>
          </Link>
        </div>
      </div>
      <BottomNav />
    </div>
  );
}

export default Maintenance;
