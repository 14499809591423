import React from "react";
import styles from "./AddedVehicles.module.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Images from "../../utils/CarImages";
import carImg from "../../asset/images/car.png";
import { dongleActions } from "../../store/redux-store/dongle-store";

const AddedVehicles = () => {
  const dispatch = useDispatch();

  const vehicles = useSelector((data) => data.auth.generalLoginData[0]);

  const vehiclesIndex = useSelector((data) => data.dongles.currentVehicle);

  const vehicle_data =
    vehicles?.vehicle_data.length !== 0
      ? vehicles?.vehicle_data
      : vehicles?.vendor_vehicle_data;

  const currVehicleHandler = (index) => {
    dispatch(dongleActions.changeVehicle(index));
  };
  return (
    <div className={styles.vehicleDetails_2}>
      {/* <div className={styles.vehicleDetails_2_head}>
        <p>Added vehicles</p>
      </div> */}
      <div className={styles.vehicleDetails_2_inner}>
        <div className={styles.vehicle_images}>
          <div className={styles.vehicleList_wrapper}>
            {vehicle_data.map((vehicle, index) => {
              const carImage = Images?.find(
                (data) =>
                  data.name?.toUpperCase() ===
                  `${
                    vehicle?.vehicle_year
                  }-${vehicle?.vehicle_make?.toUpperCase()}-${vehicle?.vehicle_model?.toUpperCase()}`
              );
              return (
                <img
                  className={
                    vehiclesIndex === index
                      ? `${styles.img}`
                      : `${styles.unactiveImg}`
                  }
                  key={index}
                  onClick={() => currVehicleHandler(index)}
                  src={carImage?.image || carImg}
                  alt="dongle"
                />
              );
            })}
          </div>

          <div className={styles.add_vehicles}>
            <Link>
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.5">
                  <rect y="0.5" width="24" height="24" rx="12" fill="#088457" />
                  <mask id="path-2-inside-1_8201_134546" fill="white">
                    <path d="M12.75 11.75V6.5H11.25V11.75H6V13.25H11.25V18.5H12.75V13.25H18V11.75H12.75Z" />
                  </mask>
                  <path
                    d="M12.75 11.75V6.5H11.25V11.75H6V13.25H11.25V18.5H12.75V13.25H18V11.75H12.75Z"
                    fill="white"
                  />
                  <path
                    d="M12.75 11.75H8.75V15.75H12.75V11.75ZM12.75 6.5H16.75V2.5H12.75V6.5ZM11.25 6.5V2.5H7.25V6.5H11.25ZM11.25 11.75V15.75H15.25V11.75H11.25ZM6 11.75V7.75H2V11.75H6ZM6 13.25H2V17.25H6V13.25ZM11.25 13.25H15.25V9.25H11.25V13.25ZM11.25 18.5H7.25V22.5H11.25V18.5ZM12.75 18.5V22.5H16.75V18.5H12.75ZM12.75 13.25V9.25H8.75V13.25H12.75ZM18 13.25V17.25H22V13.25H18ZM18 11.75H22V7.75H18V11.75ZM16.75 11.75V6.5H8.75V11.75H16.75ZM12.75 2.5H11.25V10.5H12.75V2.5ZM7.25 6.5V11.75H15.25V6.5H7.25ZM11.25 7.75H6V15.75H11.25V7.75ZM2 11.75V13.25H10V11.75H2ZM6 17.25H11.25V9.25H6V17.25ZM7.25 13.25V18.5H15.25V13.25H7.25ZM11.25 22.5H12.75V14.5H11.25V22.5ZM16.75 18.5V13.25H8.75V18.5H16.75ZM12.75 17.25H18V9.25H12.75V17.25ZM22 13.25V11.75H14V13.25H22ZM18 7.75H12.75V15.75H18V7.75Z"
                    fill="#D7FBDB"
                    mask="url(#path-2-inside-1_8201_134546)"
                  />
                </g>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddedVehicles;
