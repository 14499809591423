import React from "react";
import styles from "./SmallCard.module.css";

function SmallCard(props) {
  return (
    <div className={`${styles.buyDongle__order2} ${props.className}`}>
      {props.children}
    </div>
  );
}

export default SmallCard;
